import axios from "axios";
import {SuccessRes} from "@/model/success-res";

/***
 * @PUT
 * /holidays
 */

export interface SetHolidaysBody {
    holidays: string[]
}

export async function setHolidays(data: SetHolidaysBody) {
    return await axios.put<SuccessRes>('/holidays', data, {token: true})
}

/***
 * @POST
 * /holidays
 */

export interface GetHolidaysRes {
    holidays: string[]
}

export async function getHolidays() {
    return await axios.post<GetHolidaysRes>('/holidays', {}, {token: true})
}