import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Snack, SnackbarContext} from "@/context/SnackbarContext";
import {Box, Button, MenuItem, TextField} from "@mui/material";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {PageSizeOptions} from "@/data/options";
import dayjs from "dayjs";
import {useQuery} from "react-query";
import {QUERY_KEY} from "@/data/query-key";
import {Pagination} from "common/models/pagination";
import {messageStatusUI, WhatsappRecord} from "@models/whatsapp";
import {
    getWhatsappRecords,
    patchWhatsappRecord,
    postWhatsappRecord,
    PostWhatsappRecordBody
} from "@/api/message";
import {useFormik} from "formik";
import {constructInit} from "@/helpers/contructInit";
import {chainRules, requiredInputStringRule} from "common/input-rules";
import {ConfirmModal} from "@/components/ConfirmModal";
import {CourseSelect} from "@/components/CourseSelect";
import {
    Course,
    CourseType,
    OneOffLesson,
    REGULAR_CLASS_MONTH_FORMAT,
    RegularClassDef,
    RegularClassInstance
} from "@models/course";
import {useMessageTemplateStore} from "@/hooks/useMessageTemplateStore";
import {useAppDispatch} from "@/store";
import {getMessageTemplates, getMessageTemplate} from "@/store/slices/whatsapp-template";
import {getOneOffLessons, getRegularClasses} from "@/api/course";
import {formatOneOffLessonTimeslotStartEnd, formatRegularClassDefStartEnd} from "@/helpers/format";
import { DateTimeField } from "@mui/x-date-pickers";

export default function WhatsappRecordList() {

    const {snack, setSnack} = useContext(SnackbarContext);

    const columns: GridColDef<WhatsappRecord>[] = [
        {
            field: '_id',
            headerName: '記錄ID',
            sortable: false,
            flex: 1,
            minWidth: 200
        },
        {
            field: 'content',
            headerName: '內容',
            sortable: false,
            flex: 1,
            minWidth: 200
        },
        {
            field: 'receivers',
            headerName: '收件人',
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => {
                return (
                    <Box>
                        {
                            row.receivers.map(r => (
                                <>
                                    { `${r.name} (${r.phone})` }
                                </>
                            ))
                        }
                    </Box>
                )
            }
        },
        {
            field: 'status',
            headerName: '發送狀態',
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => {
                return (
                    <Box>
                        { messageStatusUI[row.status].title }
                    </Box>
                )
            }
        },
        {
            field: 'sendAt',
            headerName: '發送日期',
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => {
                return (
                    <Box>
                        { dayjs(row.createdAt).format('DD/MM/YYYY HH:mm') }
                    </Box>
                )
            }
        },
        {
            field: 'createdAt',
            headerName: '創建日期',
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => {
                return (
                    <Box>
                        { dayjs(row.createdAt).format('DD/MM/YYYY') }
                    </Box>
                )
            }
        },
        {
            field: 'action',
            headerName: '行動',
            sortable: false,
            minWidth: 120,
            renderCell: ({ row }) => {
                return (
                    <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
                        {/*<IconButton*/}
                        {/*    color='primary'*/}
                        {/*    size='small'*/}
                        {/*    sx={{ ml: 0.5 }}*/}
                        {/*    component={RouterLink}*/}
                        {/*    to={`/whatsappRecords/edit/${row._id}`}*/}
                        {/*>*/}
                        {/*    <EditIcon></EditIcon>*/}
                        {/*</IconButton>*/}
                    </Box>
                )
            }
        }
    ]

    const [paginationModel, setPaginationModel] = useState<{page: number, pageSize: number}>({
        page: 0,
        pageSize: 50
    })

    const [query, setQuery] = useState<Partial<Pagination>>({
        skip: paginationModel.pageSize * paginationModel.page,
        limit: paginationModel.pageSize,
        count: true
    })

    const { data, refetch, isLoading } = useQuery(
        [QUERY_KEY.WHATSAPP_RECORD_LIST, query],
        async () => {
            return (await getWhatsappRecords(query)).data
        }
    )

    const whatsappRecordList = useMemo<WhatsappRecord[]>(() => {
        if (!data)
            return []
        return data.whatsappRecords.map(u => ({
            id: u._id, // Add id for MUI
            ...u
        }))
    }, [data])

    const clickAdd = () => handleOpenModal()

    /**
     * Init
     * */

    const dispatch = useAppDispatch()

    const {
        messageTemplates,
        messageTemplateDetailLookup
    } = useMessageTemplateStore()

    useEffect(() => {
        dispatch(getMessageTemplates())
    }, [])

    /***
     * Details Modal
     */

    const emptyWhatsappRecordRequired: PostWhatsappRecordBody = {
        projectId: "",
        content: "",
        variables: {},
        studentIds: []
    }
    const emptyWhatsappRecordOptional = {
        sendAt: null
    }
    const emptyHelperFields = {
        courseId: -1,
        classId: -1,

        // One off lessons
        lessonId: -1,
        timeslotIds: [] as number[]
    }

    const [initialValues, setInitialValues] = useState<PostWhatsappRecordBody & {courseId: number, classId: number, lessonId: number, timeslotIds: number[]}>({
        ...emptyWhatsappRecordRequired,
        ...emptyHelperFields
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        onSubmit: async (values) => {
            // "content": "請假",
            //     "courseId": 6,
            //     "classId": null,
            //     "lessonId": 2,
            //     "timeslotIds": [
            //     1
            // ]
            try {
                if (editingWhatsappRecord) {
                    await patchWhatsappRecord({recordId: editingWhatsappRecord._id}, values)
                }
                else {
                    await postWhatsappRecord(values)
                }
                setSnack(Snack.success('成功儲存'))
                handleCloseModal()
                await refetch()
            }
            catch (e) {
                setSnack(Snack.error('儲存失敗'))
            }
        },
        validateOnBlur: false,
        validateOnChange: false,
        validate: (values: PostWhatsappRecordBody) => {
            let errors = {
                projectId: chainRules([requiredInputStringRule], values.projectId),
                content: chainRules([requiredInputStringRule], values.content)
            }
            Object.trimLeaves(errors, [true, {}]);
            return errors;
        }
    })
    const [editingWhatsappRecord, setEditingWhatsappRecord] = useState<WhatsappRecord | null>(null)
    const [openModal, setOpenModal] = useState(false)
    const handleOpenModal = useCallback((whatsappRecord?: WhatsappRecord) => {
        setEditingWhatsappRecord(whatsappRecord ? whatsappRecord : null)
        setInitialValues({
            ...constructInit(emptyWhatsappRecordRequired, emptyWhatsappRecordOptional, whatsappRecord),
            ...JSON.parse(JSON.stringify(emptyHelperFields))
        })
        formik.resetForm()
        setOpenModal(true)
    }, [formik])
    const handleCloseModal = () => setOpenModal(false)

    const [course, setCourse] = useState<Course | null>(null)
    const [oneOffLessons, setOneOffLessons] = useState<OneOffLesson[]>([])
    const [classes, setClasses] = useState<RegularClassInstance[]>([])
    const [classDefs, setClassDefs] = useState<RegularClassDef[]>([])

    const handleProjectIdChanged = async (newValue: string) => {
        await formik.setFieldValue(`projectId`, newValue)
        await dispatch(getMessageTemplate(newValue))
    }

    const courseChanged = async (course?: Course) => {

        // Reset data options.
        setCourse(course || null)
        setOneOffLessons([])
        // setClasses([])
        // setClassDefs([])

        await formik.setFieldValue("classId", null)

        await formik.setFieldValue("lessonId", -1)
        await formik.setFieldValue("timeslotIds", [])

        if (!course)
            return

        if (course.type === CourseType.Regular) {
            // Get classes.
            const thisMonth = dayjs().format(REGULAR_CLASS_MONTH_FORMAT)
            const classesRes = await getRegularClasses({ courseId: course._id }, {
                fromMonth: thisMonth
            })
            const instances = classesRes.data.classes
            const lookup = instances.reduce((defLookup, instance) => ({
                ...defLookup,
                [instance.classId]: instance,
            }), {} as {[key:number]: RegularClassDef})

            setClasses(instances)
            setClassDefs(Object.values(lookup))
        }
        else if (course.type === CourseType.OneOff) {
            // Get lessons.
            const oneOffLessonsRes = await getOneOffLessons({
                courseId: course._id
            }, {
            })
            setOneOffLessons(oneOffLessonsRes.data.lessons)
        }
    }

    const handleLessonIdChanged = async (newValue: string) => {
        await formik.setFieldValue(`lessonId`, Number(newValue))
        await formik.setFieldValue("timeslotIds", [])
    }

    return (
        <>
            <Button
                variant="contained"
                onClick={clickAdd}
            >
                新增
            </Button>

            {/*<CsvExportButton sx={{ml: 2}}*/}
            {/*                 disabled={selectedRows.length === 0}*/}
            {/*                 fileName={"whatsappRecords"}*/}
            {/*                 header={[]}*/}
            {/*                 rows={whatsappRecordList.filter(o => selectedRows.includes(o._id)).map(o => ([*/}
            {/*                     o._id,*/}
            {/*                     o.courseId,*/}
            {/*                     studentLookup[o.studentId] ? studentLookup[o.studentId].firstName : "-",*/}
            {/*                     studentLookup[o.studentId] ? studentLookup[o.studentId].lastName : "-",*/}
            {/*                     dayjs(o.createdAt).format('DD/MM/YYYY')*/}
            {/*                 ]))}/>*/}

            {/*<CsvImportButton<PostWhatsappRecordBody> sx={{ml: 2}}*/}
            {/*                                transformer={importTransformer}*/}
            {/*                                resultChanged={importResultChanged}/>*/}

            {/*<Paper elevation={0} sx={{ mt: 2, p: 2 }}>*/}
            {/*    <Typography*/}
            {/*        variant="h6"*/}
            {/*        component="div"*/}
            {/*        sx={{ mb: 2 }}*/}
            {/*    >*/}
            {/*        篩選*/}
            {/*    </Typography>*/}

            {/*    <Box sx={{display: "flex", alignItems: "center", mb: 2}}>*/}
            {/*        <TextField sx={{ mr: 1 }}*/}
            {/*                   fullWidth={true}*/}
            {/*                   label="搜索訂單ID"*/}
            {/*                   variant="outlined"*/}
            {/*                   size="small"*/}
            {/*                   value={query.keyword || ""}*/}
            {/*                   onChange={(e) => setQuery({...query, keyword: e.target.value as string})}*/}
            {/*        />*/}

            {/*        <StudentSelect*/}
            {/*            size="small"*/}
            {/*            fullWidth={true}*/}
            {/*            value={query.studentId || null}*/}
            {/*            onChange={(e, value) => setQuery({...query, studentId: value as string})}*/}
            {/*            textFieldProps={{*/}
            {/*                name: "studentId",*/}
            {/*                label: "學生"*/}
            {/*            }}*/}
            {/*        ></StudentSelect>*/}
            {/*    </Box>*/}

            {/*    <Box sx={{display: "flex", alignItems: "center"}}>*/}
            {/*        <DateTimePicker label="開始時間"*/}
            {/*                        slotProps={{*/}
            {/*                            textField: {size: "small"},*/}
            {/*                            field: { clearable: true }*/}
            {/*                        }}*/}
            {/*                        value={query.since ? dayjs(query.since) : null}*/}
            {/*                        format={"DD/MM/YYYY HH:mm"}*/}
            {/*                        onChange={(newValue) => setQuery({...query, since: newValue?.valueOf() || 0})}/>*/}
            {/*        <Typography component='div' sx={{ mx: 1 }}>-</Typography>*/}
            {/*        <DateTimePicker label="完結時間"*/}
            {/*                        slotProps={{*/}
            {/*                            textField: {size: "small"},*/}
            {/*                            field: { clearable: true }*/}
            {/*                        }}*/}
            {/*                        value={query.until ? dayjs(query.until) : null}*/}
            {/*                        format={"DD/MM/YYYY HH:mm"}*/}
            {/*                        onChange={(newValue) => setQuery({...query, until: newValue?.valueOf() || 0})}/>*/}
            {/*    </Box>*/}
            {/*</Paper>*/}

            <DataGrid
                sx={{
                    mt: 2,
                    backgroundColor: 'white'
                }}
                loading={isLoading}
                rows={whatsappRecordList}
                columns={columns}

                pagination
                paginationMode='server'
                paginationModel={paginationModel}
                rowCount={data?.count || 0}
                pageSizeOptions={PageSizeOptions}
                onPaginationModelChange={setPaginationModel}

                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableRowSelectionOnClick
            />

            {/* Details Modal */}
            <ConfirmModal title={`${editingWhatsappRecord ? '修改' : '新增'}訊息記錄`}
                          open={openModal}
                          onClose={handleCloseModal}
                          confirmButtonTitle={'儲存'}
                          confirmButtonProps={{
                              disabled: !formik.dirty || formik.isSubmitting
                          }}
                          onSubmit={formik.handleSubmit}>

                <TextField
                    sx={{mt: 2}}
                    name="projectId"
                    label="範本"
                    size="small"
                    fullWidth={true}
                    value={formik.values.projectId}
                    onChange={(e) => handleProjectIdChanged(e.target.value)}
                    onBlur={formik.handleBlur}
                    error={!!formik.errors.projectId && formik.touched.projectId}
                    helperText={formik.errors.projectId}
                    select>
                    {
                        messageTemplates.map(template => (
                            <MenuItem key={template.id} value={template.id}>
                                {template.name}
                            </MenuItem>
                        ))
                    }
                </TextField>

                <TextField sx={{mt: 2}}
                           id="content"
                           label="標題"
                           variant="outlined"
                           size="small"
                           fullWidth={true}
                           value={formik.values.content}
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           error={!!formik.errors.content && formik.touched.content}
                           helperText={formik.errors.content}
                />

                {
                    !!messageTemplateDetailLookup[formik.values.projectId] && messageTemplateDetailLookup[formik.values.projectId].variables.map(v =>
                        <TextField sx={{mt: 2}}
                                   id={`variables.${v.key}`}
                                   label={v.key}
                                   variant="outlined"
                                   size="small"
                                   fullWidth={true}
                                   value={formik.values.variables[v.key]}
                                   onChange={formik.handleChange}
                                   onBlur={formik.handleBlur}
                                   error={!!formik.errors.variables && !!formik.errors.variables[v.key] && !!formik.touched.variables && !!formik.touched.variables[v.key]}
                                   helperText={!!formik.errors.variables && formik.errors.variables[v.key]}
                        />
                    )
                }

                <DateTimeField sx={{mt: 2}}
                               name="sendAt"
                               label="發送日期及時間"
                               size="small"
                               fullWidth={true}
                               value={dayjs(formik.values.sendAt, 'DD/MM/YYYY HH:mm')}
                               onChange={(newValue) => {
                                   formik.setFieldValue(`sendAt`, newValue !== null ? newValue.format('DD/MM/YYYY HH:mm') : '')
                               }}
                               onBlur={formik.handleBlur}
                               FormHelperTextProps={{
                                   error: !!formik.errors.sendAt && formik.touched.sendAt
                               }}
                               helperText={formik.errors.sendAt}
                               format={"DD/MM/YYYY HH:mm"}/>

                <CourseSelect
                    sx={{mt: 2}}
                    name="courseId"
                    label="課程"
                    size="small"
                    fullWidth={true}
                    value={formik.values.courseId === -1 || !formik.values.courseId ? "" : formik.values.courseId}
                    onChange={formik.handleChange}
                    onCourseChanged={courseChanged}
                    onBlur={formik.handleBlur}
                    error={!!formik.errors.courseId && formik.touched.courseId}
                    helperText={formik.errors.courseId}
                ></CourseSelect>

                {
                    !!course && course.type === CourseType.OneOff && (
                        <>
                            <TextField
                                sx={{mt: 2}}
                                type="number"
                                name={`lessonId`}
                                label={`課堂`}
                                size="small"
                                fullWidth={true}
                                value={formik.values.lessonId === -1 ? '' : formik.values.lessonId}
                                onChange={(e) => handleLessonIdChanged(e.target.value)}
                                onBlur={formik.handleBlur}
                                error={!!formik.errors.lessonId && !!formik.touched.lessonId}
                                helperText={formik.errors.lessonId}
                                select>
                                {
                                    oneOffLessons.map((l, idx) => (
                                        <MenuItem key={l.lessonId} value={l.lessonId}>
                                            { `第${idx + 1}堂` }
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                            {
                                (formik.values.lessonId !== -1) && (
                                    <TextField
                                        sx={{mt: 2}}
                                        type="number"
                                        name={`timeslotIds`}
                                        label={`時間段`}
                                        size="small"
                                        fullWidth={true}
                                        value={formik.values.timeslotIds}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={!!formik.errors.timeslotIds && !!formik.touched.timeslotIds}
                                        helperText={formik.errors.timeslotIds}
                                        select
                                        SelectProps={{ multiple: true }}>
                                        {
                                            (oneOffLessons.find(l => l.lessonId === formik.values.lessonId)?.timeslots || []).map(t => (
                                                <MenuItem key={t.id} value={t.id}>
                                                    { formatOneOffLessonTimeslotStartEnd(t) }
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                )
                            }
                        </>
                    )
                }

                {
                    !!course && course.type === CourseType.Regular && (
                        <>
                            <TextField
                                sx={{mt: 2}}
                                type="number"
                                name="classId"
                                label="班次"
                                size="small"
                                fullWidth={true}
                                value={formik.values.classId || ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={!!formik.errors.classId && formik.touched.classId}
                                helperText={formik.errors.classId}
                                select>
                                {
                                    classDefs.map(classDef => (
                                        <MenuItem key={classDef.classId} value={classDef.classId}>
                                            { `${classDef.classId} - ${dayjs().weekday(classDef.weekday).toDate().toLocaleDateString('zh-HK', { weekday: 'long' })}` }
                                            { formatRegularClassDefStartEnd(classDef) }
                                        </MenuItem>
                                    ))
                                }
                            </TextField>


                        </>
                    )
                }
            </ConfirmModal>
        </>
    )
}
