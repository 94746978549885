import { ObjectId } from "bson";
import {MailingStatus} from "@models/product-order";

export interface I18nTxt {
    zh: string,
    en: string
}

export type DbObjectId = ObjectId | string
export type DbDate = Date | string | number

export enum Level {
    Preschool,
    P1,
    P2,
    P3,
    P4,
    P5,
    P6,
    F1,
    F2,
    F3,
    F4,
    F5,
    F6,
    Adults
}

export const levelList = [
    Level.Preschool,
    Level.P1,
    Level.P2,
    Level.P3,
    Level.P4,
    Level.P5,
    Level.P6,
    Level.F1,
    Level.F2,
    Level.F3,
    Level.F4,
    Level.F5,
    Level.F6,
    Level.Adults
] as const;

export const levelUI = {
    [Level.Preschool]: {
        title: "學前",
    },
    [Level.P1]: {
        title: "小一",
    },
    [Level.P2]: {
        title: "小二",
    },
    [Level.P3]: {
        title: "小三",
    },
    [Level.P4]: {
        title: "小四",
    },
    [Level.P5]: {
        title: "小五",
    },
    [Level.P6]: {
        title: "小六",
    },
    [Level.F1]: {
        title: "中一",
    },
    [Level.F2]: {
        title: "中二",
    },
    [Level.F3]: {
        title: "中三",
    },
    [Level.F4]: {
        title: "中四",
    },
    [Level.F5]: {
        title: "中五",
    },
    [Level.F6]: {
        title: "中六",
    },
    [Level.Adults]: {
        title: "成人",
    },
};
