import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "@/store";
import * as api from "@/api/message";
import {MessageTemplateDetailInfo, MessageTemplateListInfo} from "@models/whatsapp";
import {GetWhatsappTemplatesRes} from "@/api/message";

export interface MessageTemplateState {
    messageTemplates: MessageTemplateListInfo[]
    messageTemplateDetailLookup: {[key: string]: MessageTemplateDetailInfo}
}

const initialState: MessageTemplateState = {
    messageTemplates: [],
    messageTemplateDetailLookup: {},
}

const slice = createSlice({
    name: 'messageTemplate',
    initialState,
    reducers: {
        setMessageTemplates(state: MessageTemplateState, action: PayloadAction<MessageTemplateListInfo[]>) {
            state.messageTemplates = action.payload
        },
        setMessageTemplateLookup(state: MessageTemplateState, action: PayloadAction<{[key: string]: MessageTemplateDetailInfo}>) {
            state.messageTemplateDetailLookup = action.payload
        },
    }
})

export const { reducer } = slice

export const { setMessageTemplates, setMessageTemplateLookup } = slice.actions

export const getMessageTemplates = (): AppThunk<Promise<GetWhatsappTemplatesRes | null>> =>
    async (dispatch, getState) => {
        try {
            const res = await api.getWhatsappTemplates()
            dispatch(slice.actions.setMessageTemplates(res.data.whatsappTemplates))
            return res.data
        } catch (err) {
            console.error(err)
        }
        return null
    }
    
export const getMessageTemplate = (templateId: string): AppThunk<Promise<MessageTemplateDetailInfo | null>> =>
    async (dispatch, getState) => {
        const state = getState().messageTemplate
        if (!!state.messageTemplateDetailLookup[templateId])
            return state.messageTemplateDetailLookup[templateId]
        try {
            const res = await api.getWhatsappTemplate({
                templateId
            })
            dispatch(slice.actions.setMessageTemplateLookup({
                ...state.messageTemplateDetailLookup,
                [templateId]: res.data.whatsappTemplate
            }))
            return res.data.whatsappTemplate
        } catch (err) {
            console.error(err)
        }
        return null
    }
