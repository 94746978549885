import React, {useContext, useEffect, useMemo, useState} from "react";
import {Snack, SnackbarContext} from "@/context/SnackbarContext";
import {Box, Button, Grid, IconButton, InputAdornment, MenuItem, Paper, TextField, Typography} from "@mui/material";
import {chainRules, requiredInputStringRule} from "common/input-rules";
import {
    AdminCreateProductOrderPayload,
    CreateProductOrderItem,
    MailingStatus,
    mailingStatusList,
    mailingStatusUI,
    mailingMethodList, mailingMethodUI, ProductOrder,
} from "@models/product-order";
import { useFormik } from "formik";
import {StudentSelect} from "@/components/StudentSelect";
import {
    getProductOrder,
    patchProductOrder,
    PatchProductOrderData, postAcceptProductOrderPayment,
    postProductOrders,
    postRejectProductOrderPayment, PostRejectProductOrderPaymentBody
} from "@/api/product-order";
import {useNavigate, useParams} from "react-router-dom";
import {constructInit} from "@/helpers/contructInit";
import {Order, OrderStatus, orderStatusList, orderStatusUI, paymentMethodList, paymentMethodUI} from "@models/order";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import { ProductSelect } from "@/components/ProductSelect";
import DeleteIcon from "@mui/icons-material/Delete";
import {DateTimeField} from "@mui/x-date-pickers/DateTimeField";
import dayjs from "dayjs";
import {isEmpty} from "common/helpers";
import {Product} from "@models/product";

export default function ProductOrderCreate() {

    const navigate = useNavigate();
    const {snack, setSnack} = useContext(SnackbarContext);
    const routeParams = useParams();
    const productOrderId = useMemo<string | null>(() => {
        return routeParams && routeParams.id ? routeParams.id : null
    }, [routeParams]);
    const [productOrder, setProductOrder] = useState<ProductOrder | null>(null) // For display non editable fields.

    const emptyProductOrderRequired: AdminCreateProductOrderPayload = {
        productList: [] as CreateProductOrderItem[],
        studentId: "",
        price: 0,
        status: OrderStatus.Pending,
        mailingStatus: MailingStatus.Pending,
        mailingMethod: "pick-up"
    }

    const emptyProductOrderOptional = {
        paymentMethod: null,
        paidAt: null,
        refundedAt: null
    }

    const [initialValues, setInitialValues] = useState({
        ...emptyProductOrderRequired
    });

    useEffect(() => {
        initialiseForm()
    }, []);

    const initialiseForm = async () => {
        if (productOrderId) {
            await getProductOrder({id: productOrderId}).then(res => {
                const initialValues = constructInit(emptyProductOrderRequired, emptyProductOrderOptional, res.data.productOrder)
                initialValues.productList = res.data.productOrder.productItems.map(item => ({
                    productId: item.product._id,
                    quantity: item.quantity
                }))
                setProductOrder(res.data.productOrder)
                setInitialValues(initialValues)
                formik.resetForm()
            })
        }
    }

    const formik = useFormik<AdminCreateProductOrderPayload>({
        enableReinitialize: true,
        initialValues: initialValues,
        onSubmit: async (values, {setFieldValue}) => {
            try {
                const body = {...values}
                if (values.status !== OrderStatus.Paid) {
                    delete body.paymentMethod
                    delete body.paidAt
                }
                if (values.status !== OrderStatus.Refunded) {
                    delete body.refundedAt
                }

                let poid = ""
                if (productOrderId) {
                    const updateBody: PatchProductOrderData = {
                        price: body.price
                    }
                    if (!isEmpty(body.paymentMethod))
                        updateBody.paymentMethod = body.paymentMethod
                    if (!isEmpty(body.paidAt))
                        updateBody.paidAt = body.paidAt
                    if (!isEmpty(body.refundedAt))
                        updateBody.refundedAt = body.refundedAt
                    await patchProductOrder({id: productOrderId}, updateBody)
                }
                else {
                    const res = await postProductOrders(body)
                    poid = res.data.productOrder._id
                }
                setSnack(Snack.success('成功儲存'))
                if (!productOrderId)
                    navigate(`/product-orders/edit/${poid}`, {
                        replace: true
                    })
                else
                    await initialiseForm()
            }
            catch (e) {
                setSnack(Snack.error('儲存失敗'))
            }
        },
        validateOnBlur: false,
        validateOnChange: false,
        validate: (values: AdminCreateProductOrderPayload) => {
            let errors = {
                studentId: chainRules([requiredInputStringRule], values.studentId),
            }
            Object.trimLeaves(errors, [true, {}])
            return errors
        }
    });

    const clickAddProduct = () => {
        formik.setFieldValue("productList", [
            ...formik.values.productList,
            {
                productId: 0,
                quantity: 1
            }
        ])
    }

    const columns: GridColDef<CreateProductOrderItem>[] = [
        {
            field: 'productId',
            headerName: '產品',
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({ row, id }) => {
                return (
                    <Box sx={{ width: '100%' }}>
                        <ProductSelect
                            size="small"
                            fullWidth={true}
                            value={!!formik.values.productList[id].productId ? String(formik.values.productList[id].productId) : null}
                            onChange={(e, value) => formik.setFieldValue(`productList.${id}.productId`, value)}
                            onBlur={formik.handleBlur}
                            textFieldProps={{
                                name: `productList.${id}.productId`,
                                label: "產品",
                                error: !!formik.errors.productList && !!formik.errors.productList[id].productId && formik.touched.productList && formik.touched.productList[id].productId,
                                helperText: formik.errors.productList && formik.errors.productList[id].productId,
                            }}
                            disabled={!!productOrderId}
                        ></ProductSelect>
                    </Box>
                )
            }
        },
        {
            field: 'quantity',
            headerName: '數量',
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({ row, id }) => {
                return (
                    <Box sx={{ width: '100%' }}>
                        <TextField type="number"
                                   name={`productList.${id}.quantity`}
                                   label="數量"
                                   size="small"
                                   fullWidth={true}
                                   value={formik.values.productList[id].quantity}
                                   onChange={formik.handleChange}
                                   onBlur={formik.handleBlur}
                                   error={!!formik.errors.productList && !!formik.errors.productList[id].quantity && formik.touched.productList &&  formik.touched.productList[id].quantity}
                                   helperText={formik.errors.productList && formik.errors.productList[id].quantity}
                                   disabled={!!productOrderId}
                        />
                    </Box>
                )
            }
        },
        ...(!productOrderId ? [
            {
                field: 'action',
                headerName: '行動',
                sortable: false,
                minWidth: 120,
                renderCell: ({ row }) => {
                    return (
                        <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
                            <IconButton
                                color='error' size='small'
                                onClick={() => clickDelete(row)}
                            >
                                <DeleteIcon></DeleteIcon>
                            </IconButton>
                        </Box>
                    )
                }
            }
        ] : [])
    ]

    const clickDelete = (item: CreateProductOrderItem) => {
        formik.setFieldValue("productList", [
            ...formik.values.productList.filter(p => p.productId !== item.productId),
        ])
    }

    /**
     * Payment Proof
     * */

    const [openPaymentProofModal, setOpenPaymentProofModal] = useState(false)

    const paymentProofFormik = useFormik<PostRejectProductOrderPaymentBody>({
        enableReinitialize: true,
        initialValues: {
            rejectedReason: ""
        },
        onSubmit: async (values, {setFieldValue}) => {
            try {
                if (!!productOrderId)
                    await postRejectProductOrderPayment({ id: productOrderId }, values)
                setSnack(Snack.success('成功儲存'))
                setOpenPaymentProofModal(false)
                initialiseForm()
            }
            catch (e) {
                setSnack(Snack.error('儲存失敗'))
            }

        },
        validateOnBlur: false,
        validateOnChange: false,
        validate: values => {
            let errors = {
                rejectedReason: chainRules([requiredInputStringRule], values.rejectedReason),
            }
            Object.trimLeaves(errors, [true, {}])
            return errors
        }
    });

    const handleAcceptPayment = async () => {
        if (!productOrderId)
            return
        try {
            await postAcceptProductOrderPayment({ id: productOrderId })
            setSnack(Snack.success('成功接受'))
            initialiseForm()
        }
        catch (e) {
            setSnack(Snack.error('接受失敗'))
        }
    }

    const handleRejectPayment = async () => {
        setOpenPaymentProofModal(true)
    }

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Button type="submit"
                        variant="contained"
                        disabled={!formik.dirty || formik.isSubmitting}>
                    儲存
                </Button>

                {
                    (!!productOrder && productOrder.status === OrderStatus.OfflineSubmitted && !!productOrder.receiptRecords) && (
                        <Paper sx={{p: 4, mt: 2}}>
                            <Typography
                                variant="h6"
                                component="div"
                            >
                                審批付款證明
                            </Typography>
                            <Grid sx={{mt: 2}} container spacing={2}>
                                {
                                    productOrder.receiptRecords.filter(r => !r.rejectedReason).map(r => (
                                        <Grid item xs={2}>
                                            <img
                                                style={{objectFit: "cover", width: "100%", aspectRatio: 1}}
                                                src={r.imageUrl}
                                                loading="lazy"
                                            />
                                        </Grid>
                                    ))
                                }
                            </Grid>
                            <Box sx={{mt: 2}}>
                                <Button sx={{mr: 2}}
                                        variant="contained"
                                        color="success"
                                        onClick={handleAcceptPayment}>
                                    接受付款證明
                                </Button>
                                <Button variant="contained"
                                        color="error"
                                        onClick={handleRejectPayment}>
                                    拒絕付款證明
                                </Button>
                            </Box>
                        </Paper>
                    )
                }
                
                <Paper sx={{p: 4, mt: 2}}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <TextField
                                type="number"
                                name="status"
                                label="狀態"
                                size="small"
                                fullWidth={true}
                                value={formik.values.status}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={!!formik.errors.status && formik.touched.status}
                                helperText={formik.errors.status}
                                select>
                                {
                                    orderStatusList.map(s => (
                                        <MenuItem key={s} value={s}>
                                            {`${orderStatusUI[s].title}`}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                type="number"
                                name="mailingStatus"
                                label="郵寄狀態"
                                size="small"
                                fullWidth={true}
                                value={formik.values.mailingStatus}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={!!formik.errors.mailingStatus && formik.touched.mailingStatus}
                                helperText={formik.errors.mailingStatus}
                                select>
                                {
                                    mailingStatusList.map(s => (
                                        <MenuItem key={s} value={s}>
                                            {`${mailingStatusUI[s].title}`}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                name="mailingMethod"
                                label="郵寄方法"
                                size="small"
                                fullWidth={true}
                                value={formik.values.mailingMethod}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={!!formik.errors.mailingMethod && formik.touched.mailingMethod}
                                helperText={formik.errors.mailingMethod}
                                select>
                                {
                                    mailingMethodList.map(s => (
                                        <MenuItem key={s} value={s}>
                                            {`${mailingMethodUI[s].title}`}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <StudentSelect
                                size="small"
                                fullWidth={true}
                                value={formik.values.studentId || null}
                                onChange={(e, value) => formik.setFieldValue("studentId", value)}
                                onBlur={formik.handleBlur}
                                textFieldProps={{
                                    name: "studentId",
                                    label: "學生",
                                    error: !!formik.errors.studentId && formik.touched.studentId,
                                    helperText: formik.errors.studentId
                                }}
                                disabled={!!productOrderId}
                            ></StudentSelect>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField type="number"
                                       name="price"
                                       label="價錢"
                                       size="small"
                                       fullWidth={true}
                                       value={formik.values.price}
                                       onChange={formik.handleChange}
                                       onBlur={formik.handleBlur}
                                       error={!!formik.errors.price && formik.touched.price}
                                       helperText={formik.errors.price}
                                       InputProps={{
                                           startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                       }}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            {
                                formik.values.status === OrderStatus.Paid && (
                                    <DateTimeField label="付款日期"
                                                   size="small"
                                                   value={dayjs(formik.values.paidAt)}
                                                   onChange={(newValue) => {
                                                       formik.setFieldValue(`paidAt`, newValue !== null ? newValue.format('YYYY-MM-DD') : '')
                                                   }}
                                                   onBlur={formik.handleBlur}
                                                   FormHelperTextProps={{
                                                       error: !!formik.errors.paidAt && formik.touched.paidAt
                                                   }}
                                                   helperText={formik.errors.paidAt}
                                                   format={"DD/MM/YYYY"}/>
                                )
                            }
                        </Grid>
                        <Grid item xs={3}>
                            {
                                formik.values.status === OrderStatus.Paid && (
                                    <TextField
                                        name="paymentMethod"
                                        label="付款方法"
                                        size="small"
                                        fullWidth={true}
                                        value={formik.values.paymentMethod}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={!!formik.errors.paymentMethod && formik.touched.paymentMethod}
                                        helperText={formik.errors.paymentMethod}
                                        select>
                                        {
                                            paymentMethodList.map((m, idx) => (
                                                <MenuItem key={m} value={m}>
                                                    {paymentMethodUI[m].title}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                )
                            }
                        </Grid>
                        <Grid item xs={3}>
                            {
                                formik.values.status === OrderStatus.Refunded && (
                                    <DateTimeField sx={{mt: 2}}
                                                   label="退款日期"
                                                   size="small"
                                                   value={dayjs(formik.values.refundedAt)}
                                                   onChange={(newValue) => {
                                                       formik.setFieldValue(`refundedAt`, newValue !== null ? newValue.format('YYYY-MM-DD') : '')
                                                   }}
                                                   onBlur={formik.handleBlur}
                                                   FormHelperTextProps={{
                                                       error: !!formik.errors.refundedAt && formik.touched.refundedAt
                                                   }}
                                                   helperText={formik.errors.refundedAt}
                                                   format={"DD/MM/YYYY"}/>
                                )
                            }
                        </Grid>

                    </Grid>
                </Paper>
                <Paper sx={{p: 4, mt: 2}}>
                    <DataGrid
                        sx={{
                            mt: 2,
                            backgroundColor: 'white'
                        }}
                        // loading={isLoading}
                        rows={formik.values.productList.map((p, idx) => ({...p, id: idx}))}
                        columns={columns}

                        hideFooterPagination={true}

                        disableColumnFilter
                        disableColumnMenu
                        disableColumnSelector
                        disableRowSelectionOnClick
                    />

                    {
                        !productOrderId && (
                            <Box>
                                <Button sx={{ mt: 2 }}
                                        variant="contained"
                                        fullWidth={true}
                                        onClick={clickAddProduct}>
                                    新增產品
                                </Button>
                            </Box>
                        )
                    }
                </Paper>
            </form>


        </>
    )
}
