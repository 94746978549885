import {CreateBannerPayload, UpdateBannerPayload, Banner} from "@models/banner";
import axios from "axios";
import {SuccessRes} from "@/model/success-res";

/***
 * @GET
 * /banners
 */

export interface GetBannersRes {
    banners: Banner[]
}

export async function getBanners() {
    return await axios.get<GetBannersRes>('/banners', {
        token: true
    })
}

/***
 * @GET
 * /banners/{bannerId}
 */

export interface GetBannerPathParams {
    id: string
}

export interface GetBannerRes {
    banner: Banner
}

export async function getBanner(pathParams: GetBannerPathParams) {
    return await axios.get<GetBannerRes>(`/banners/${pathParams.id}`, {
        token: true
    })
}

/***
 * @POST
 * /banners
 */

export type PostBannerBody = CreateBannerPayload

export interface PostBannerRes {
    banner: Banner
}

export async function postBanner(data: PostBannerBody) {
    return await axios.post<PostBannerRes>('/banners', data, {token: true})
}

/***
 * @PATCH
 * /banners/{userId}
 */

export interface PatchBannerPathParams {
    id: string
}

export type PatchBannerData = UpdateBannerPayload

export async function patchBanner(pathParams: PatchBannerPathParams, data: PatchBannerData) {
    return await axios.patch<SuccessRes>(`/banners/${pathParams.id}`, data, {token: true})
}

/***
 * @DELETE
 * /banners/{userId}
 */

export interface DeleteBannerPathParams {
    id: string
}

export async function deleteBanner(pathParams: DeleteBannerPathParams) {
    return await axios.delete<SuccessRes>(`/banners/${pathParams.id}`, {token: true})
}

