import {OneOffLessonTimeslot, RegularClassDef} from "@models/course";
import dayjs from "dayjs";

export function formatOneOffLessonTimeslotStartEnd(t: OneOffLessonTimeslot) {
    return `${dayjs(t.start).format('DD/MM/YYYY HH:mm')} - ${dayjs(t.end).format('DD/MM/YYYY HH:mm')}`
}

export function formatRegularClassDefStartEnd(classDef: RegularClassDef) {
    return `${dayjs(classDef.start).format('HH:mm')} - ${dayjs(classDef.end).format('HH:mm')}`
}

const aphRgx = /^[a-zA-Z ]+$/;

export function formatFullName(doc: { firstName: string; lastName: string }) {
    const ln = doc.lastName || "";
    const fn = doc.firstName || "";
    return aphRgx.test(ln) && aphRgx.test(fn) ? [fn, ln].join(" ") : ln + fn;
}