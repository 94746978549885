import { QUERY_KEY } from '@/data/query-key'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import React, {useContext, useMemo, useState} from 'react'
import {useQuery} from 'react-query'
import {Box, Paper, Typography} from "@mui/material";
import {SnackbarContext} from "@/context/SnackbarContext";
import dayjs from "dayjs";
import {
    getAccountReport
} from "@/api/account";
import {ConfirmContext} from "@/context/ConfirmContext";
import {CsvExportButton} from "@/components/CsvExportButton";
import {GridRowSelectionModel} from "@mui/x-data-grid/models/gridRowSelectionModel";
import {DbDate} from "@models/common";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";

type AccountingRecord = {
    id: string,
    title: string,
    date: DbDate | undefined,
    amount: number
}

export default function AccountingList() {

    const {snack, setSnack} = useContext(SnackbarContext);
    const {confirmation, setConfirmation} = useContext(ConfirmContext);

    const columns: GridColDef<AccountingRecord>[] = [
        // {
        //     field: '_id',
        //     headerName: '支出ID',
        //     sortable: false,
        //     flex: 1,
        //     minWidth: 200
        // },
        {
            field: 'title',
            headerName: '標題',
            sortable: false,
            flex: 1,
            minWidth: 200
        },
        {
            field: 'amount',
            headerName: '數目',
            sortable: false,
            flex: 1,
            minWidth: 200
        },
        {
            field: 'date',
            headerName: '日期',
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => {
                return (
                    <Box>
                        { dayjs(row.date).format('DD/MM/YYYY') }
                    </Box>
                )
            }
        }
    ]

    const [paginationModel, setPaginationModel] = useState<{page: number, pageSize: number}>({
        page: 0,
        pageSize: 50
    })
    const [keyword, setKeyword] = useState<string | null>(null)

    const [query, setQuery] = useState({
        since: dayjs().subtract(1, "month").valueOf(),
        until: dayjs().valueOf()
    })

    const { data, refetch, isLoading } = useQuery(
        [QUERY_KEY.ACCOUNT_LIST, query],
        async () => {
            return (await getAccountReport(query)).data
        }
    )

    const accountingList = useMemo(() => {
        if (!data)
            return [] as AccountingRecord[]
        return [
            ...data.accountRecords.map(r => ({
                id: r._id?.toString() || "", // Add id for MUI
                title: r.title,
                date: r.recordDate,
                amount: r.amount
            })),
            ...data.orders.map(o => ({
                id: o._id?.toString() || "", // Add id for MUI
                title: o._id,
                date: o.paidAt,
                amount: o.price
            })),
            ...data.productOrders.map(o => ({
                id: o._id?.toString() || "", // Add id for MUI
                title: o._id,
                date: o.paidAt,
                amount: o.price
            }))
        ].sort((a, b) => dayjs(a.date || 0).valueOf() - dayjs(b.date || 0).valueOf())
    }, [data])

    /***
     * Row actions
     */

    const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);

    const handleRowSelectionChange = (rowSelectionModel: GridRowSelectionModel) => {
        setSelectedRows(rowSelectionModel);
    };

    const clickApplyFilter = () => {
        refetch()
    }

    return (
        <>
            <CsvExportButton disabled={selectedRows.length === 0}
                             fileName={"accountings"}
                             header={[]}
                             rows={accountingList.filter(o => selectedRows.includes(o.id)).map(e => ([
                                 e.id,
                                 e.title,
                                 e.amount,
                                 dayjs(e.date).format('DD/MM/YYYY')
                             ]))}/>

            <Paper elevation={0} sx={{ mt: 2, p: 2 }}>
                <Typography
                    variant="h6"
                    component="div"
                    sx={{ mb: 2 }}
                >
                    篩選
                </Typography>
                <Box sx={{display: "flex", alignItems: "center"}}>
                    <DateTimePicker label="開始時間"
                                    slotProps={{
                                        textField: {size: "small"}
                                    }}
                                    value={query.since ? dayjs(query.since) : null}
                                    format={"DD/MM/YYYY HH:mm"}
                                    onChange={(newValue) => setQuery({...query, since: newValue?.valueOf() || 0})}/>
                    <Typography component='div' sx={{ mx: 1 }}>-</Typography>
                    <DateTimePicker label="完結時間"
                                    slotProps={{
                                        textField: {size: "small"}
                                    }}
                                    value={query.until ? dayjs(query.until) : null}
                                    format={"DD/MM/YYYY HH:mm"}
                                    onChange={(newValue) => setQuery({...query, until: newValue?.valueOf() || 0})}/>                </Box>
            </Paper>

            <DataGrid
                sx={{
                    mt: 2,
                    backgroundColor: 'white'
                }}
                loading={isLoading}
                rows={accountingList}
                columns={columns}

                // pagination
                // paginationMode='server'
                // paginationModel={paginationModel}
                // rowCount={data?.count || 0}
                // pageSizeOptions={PageSizeOptions}
                // onPaginationModelChange={setPaginationModel}

                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableRowSelectionOnClick

                checkboxSelection
                onRowSelectionModelChange={handleRowSelectionChange}
            />
        </>
    )
}
