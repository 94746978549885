import {Box, Button, FormControlLabel, IconButton, Switch, TextField} from "@mui/material";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import React, {useCallback, useContext, useMemo, useState} from "react";
import {Snack, SnackbarContext} from "@/context/SnackbarContext";
import DeleteIcon from "@mui/icons-material/Delete";
import {deleteBanner, getBanners, patchBanner, postBanner} from "@/api/banner";
import {ConfirmModal} from "@/components/ConfirmModal";
import {useFormik} from "formik";
import {Banner} from "@models/banner";
import DoneIcon from "@mui/icons-material/Done";
import dayjs from "dayjs";
import {constructInit} from "@/helpers/contructInit";
import {useQuery} from "react-query";
import {QUERY_KEY} from "@/data/query-key";
import {FileUploadTextField} from "@/components/FileUploadTextField";
import {uploadToGoogleCloud} from "@/helpers/uploadToGoogleCloud";
import EditIcon from "@mui/icons-material/Edit";
import {Confirm, ConfirmContext} from "@/context/ConfirmContext";
import {chainRules, formatUrlOrUrlPathRule} from "common/input-rules";

type GridBanner = Banner & {
    id: string
}

export default function BannerList() {

    const {snack, setSnack} = useContext(SnackbarContext);
    const {confirmation, setConfirmation} = useContext(ConfirmContext);

    const columns: GridColDef<GridBanner>[] = [
    // button: {
    //     url: string,
    //         text: I18nTxt
    // } | null
        {
            field: '_id',
            headerName: '橫幅ID',
            sortable: false,
            flex: 1,
            minWidth: 200
        },
        {
            field: 'url',
            headerName: 'URL',
            sortable: false,
            flex: 1,
            minWidth: 200
        },
        {
            field: 'type',
            headerName: '類型',
            sortable: false,
            flex: 1,
            minWidth: 200
        },
        {
            field: 'hidden',
            headerName: '已隱藏',
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => {
                return (
                    row.hidden ? <DoneIcon /> : <></>
                )
            }
        },
        {
            field: 'created',
            headerName: '創建日期',
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => {
                return (
                    <Box>
                        { dayjs(row.created).format('DD/MM/YYYY') }
                    </Box>
                )
            }
        },
        {
            field: "action",
            headerName: "行動",
            sortable: false,
            minWidth: 120,
            renderCell: ({ row }) => {
                return (
                    <Box sx={{ display: "flex", justifyContent: "end", width: "100%" }}>
                        <IconButton
                            color='primary' size='small'
                            onClick={() => clickEdit(row)}
                            sx={{ ml: 0.5 }}
                        >
                            <EditIcon></EditIcon>
                        </IconButton>
                        <IconButton
                            color='error'
                            size='small'
                            onClick={() => clickDelete(row)}
                            sx={{ ml: 0.5 }}
                        >
                            <DeleteIcon></DeleteIcon>
                        </IconButton>
                    </Box>
                );
            },
        },
    ];

    /**
     * Init
     * */

    const { data, refetch, isLoading } = useQuery(
        [QUERY_KEY.BANNER_LIST],
        async () => {
            return (await getBanners()).data
        }
    )

    const bannerList = useMemo<GridBanner[]>(() => {
        if (!data)
            return []
        return data.banners.map(c => ({
            id: String(c._id || ""),
            ...c
        }))
    }, [data])

    /***
     * Row actions
     */

    const clickEdit = (row: GridBanner) => handleOpenModal(JSON.parse(JSON.stringify(row)))

    const clickDelete = (row: GridBanner) => {
        setConfirmation(Confirm.delete({
            confirmButtonProps: {
                onClick: async () => {
                    if (!row._id)
                        return
                    try {
                        await deleteBanner({id: row._id.toString()})
                        setSnack(Snack.success('成功刪除'))
                        await refetch()
                        setConfirmation(Confirm.close())
                    }
                    catch (e) {
                        setSnack(Snack.error('刪除失敗'))
                    }
                }
            }
        }))
    }

    const clickSave = async () => {
        // try {
        //     await setBanners({
        //         banners: bannerList.map(h => h.date)
        //     });
        //     setSnack(Snack.success('成功儲存'));
        //     // await refetch()
        // }
        // catch (e) {
        //     setSnack(Snack.error('儲存失敗'));
        // }
    }

    /***
     * Add Banner Modal
     */

    const emptyBannerRequired = {
        url: "",
        type: "image" as "image" | "video",
        button: {
            url: "",
            text: {
                zh: "",
                en: ""
            }
        },
        hidden: true,
    }
    const emptyBannerOptional = {}

    const [initialValues, setInitialValues] = useState({
        ...emptyBannerRequired
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        onSubmit: async (values, {setFieldValue}) => {

            const body = {...values}
            try {
                if (file) {
                    const publicUrl = await uploadToGoogleCloud(file)
                    setFieldValue("url", publicUrl)
                    body.url = publicUrl
                }
            }
            catch (e) {
                setSnack(Snack.error('儲存失敗'))
                return
            }

            try {
                if (!!editingBanner) {
                    await patchBanner({ id: editingBanner.id }, body)
                }
                else {
                    await postBanner(body)
                }
                setSnack(Snack.success('成功儲存'))
                handleCloseModal()
                refetch()
            }
            catch (e) {
                setSnack(Snack.error('儲存失敗'))
            }
        },
        validateOnBlur: false,
        validateOnChange: false,
        validate: values => {
            let errors = {
                button: {
                //     text: {
                //         zh: chainRules([requiredInputStringRule], values.button.text.zh),
                //         en: chainRules([requiredInputStringRule], values.button.text.en),
                //     },
                    url: chainRules([formatUrlOrUrlPathRule], values.button.url)
                }
            }
            Object.trimLeaves(errors, [true, {}]);
            return errors;
        }
    })
    const [editingBanner, setEditingBanner] = useState<GridBanner | null>(null)
    const [openModal, setOpenModal] = useState(false)
    const handleOpenModal = useCallback((banner?: GridBanner) => {
        setEditingBanner(banner || null)
        setInitialValues(constructInit(emptyBannerRequired, emptyBannerOptional, banner))
        formik.resetForm()
        setOpenModal(true)
    }, [formik])
    const handleCloseModal = () => setOpenModal(false)

    /**
     * Thumbnail
     * */

    const [file, setFile] = useState<File | null>(null)
    const handleFileChange = (file: File) => {
        setFile(file)
        const url = URL.createObjectURL(file)
        if (file.type.match('video.*'))
            formik.setFieldValue("type", 'video')
        else
            formik.setFieldValue("type", 'image')
        formik.setFieldValue("url", url)
    }

    return (
        <>
            {/*<Button variant="contained" onClick={clickSave}>*/}
            {/*    儲存*/}
            {/*</Button>*/}

            <DataGrid
                sx={{
                    mt: 2,
                    backgroundColor: "white",
                }}
                // loading={isLoading}
                rows={bannerList}
                columns={columns}
                getRowId={(row) => (row.id || "").toString()}

                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableRowSelectionOnClick

                // checkboxSelection
                // onRowSelectionModelChange={handleRowSelectionChange}
            />

            <Box>
                <Button sx={{ mt: 2 }}
                        variant="contained"
                        fullWidth={true}
                        onClick={() => handleOpenModal()}>
                    新增橫幅
                </Button>
            </Box>

            {/* Modal */}
            <ConfirmModal title={`新增橫幅`}
                          open={openModal}
                          onClose={handleCloseModal}
                          confirmButtonProps={{
                              disabled: !formik.dirty || formik.isSubmitting
                          }}
                          onSubmit={formik.handleSubmit}>
                <FormControlLabel
                    sx={{mt: 2}}
                    control={
                        <Switch name="hidden"
                                checked={formik.values.hidden}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}/>
                    }
                    label="隱藏橫幅" />

                <Box sx={{display: "flex", alignItems: "center", mt: 2}}>
                    <TextField sx={{ mr: 1 }}
                               name="button.text.zh"
                               label="中文按鈕標題"
                               size="small"
                               fullWidth={true}
                               value={formik.values.button?.text.zh}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               error={!!formik.errors.button?.text?.zh && formik.touched.button?.text?.zh}
                               helperText={formik.errors.button?.text?.zh}
                    />
                    <TextField name="button.text.en"
                               label="英文按鈕標題"
                               size="small"
                               fullWidth={true}
                               value={formik.values.button?.text.en}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               error={!!formik.errors.button?.text?.en && formik.touched.button?.text?.en}
                               helperText={formik.errors.button?.text?.en}
                    />
                </Box>
                <TextField sx={{mt: 2}}
                           name="button.url"
                           label="按鈕連結"
                           size="small"
                           fullWidth={true}
                           value={formik.values.button?.url}
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           error={!!formik.errors.button?.url && formik.touched.button?.url}
                           helperText={formik.errors.button?.url}
                />

                <FileUploadTextField
                    textFieldProps={{
                        name: "url",
                        label: "圖片 或 影片 URL",
                        fullWidth: true,
                        value: formik.values.url,
                        onChange: formik.handleChange,
                        onBlur: formik.handleBlur,
                        error: !!formik.errors.url && formik.touched.url,
                        helperText: formik.errors.url,
                        sx: {mt: 2}
                        // readOnly
                    }}
                    accept={"image/*, video/*"}
                    change={handleFileChange}
                />
                { formik.values.url &&
                    <Box sx={{mt: 2}}>
                        {
                            formik.values.type === 'image' ?
                                <img style={{width: "100%"}} src={formik.values.url} />
                                :
                                <video style={{width: "100%"}} controls>
                                    <source src={formik.values.url} type="video/mp4" />
                                    <source src={formik.values.url} type="video/ogg" />
                                    Your browser does not support the video tag.
                                </video>
                        }
                    </Box>
                }
            </ConfirmModal>
        </>
    );
}
