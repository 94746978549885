import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "@/store";
import * as api from "@/api/tutor";
import {Tutor} from "@models/tutor";

export interface TutorState {
    tutorLookup: {[key: string]: Tutor}
}

const initialState: TutorState = {
    tutorLookup: {},
}

const slice = createSlice({
    name: 'tutor',
    initialState,
    reducers: {
        setTutorLookup(state: TutorState, action: PayloadAction<{[key: string]: Tutor}>) {
            state.tutorLookup = action.payload
        },
    }
})

export const { reducer } = slice

export const { setTutorLookup } = slice.actions

export const getTutorLookup = (): AppThunk<Promise<any>> =>
    async (dispatch, getState) => {
        try {
            const res = await api.getTutors({})
            await dispatch(slice.actions.setTutorLookup(res.data.tutors.toDict()))
            return res.data
        } catch (err) {
            console.error(err)
        }
    }
