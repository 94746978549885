import React, {FC, useContext, useEffect} from "react";
import {useFormik} from "formik";
import {
    patchAdminPassword,
    PatchAdminPasswordData,
    patchAdminSelfPassword, PatchAdminSelfPasswordData,
} from "@/api/admin";
import {Snack, SnackbarContext} from "@/context/SnackbarContext";
import {chainRules, lengthRule, requiredInputStringRule} from "common/input-rules";
import {TextField} from "@mui/material";
import {ConfirmModal} from "@/components/ConfirmModal";
import {useAuthStore} from "@/hooks/useAuthStore";

export type AdminEditPasswordModal = {
    open: boolean
    onClose: (saved: boolean) => void

    editingAdminId: string
}

export const AdminEditPasswordModal: FC<AdminEditPasswordModal> = (props) => {

    const {
        open,
        onClose,

        editingAdminId
    } = props

    const {snack, setSnack} = useContext(SnackbarContext)

    const {
        adminSelf
    } = useAuthStore()

    useEffect(() => {
        if (open) {
            formik.resetForm()
        }
    }, [open])

    const formik = useFormik({
        initialValues: {
            oldPassword: "",
            password: "",
        },
        onSubmit: async (values) => {
            try {
                if (!adminSelf || !editingAdminId)
                    return

                if (!adminSelf.superAdmin && adminSelf._id !== editingAdminId) {
                    setSnack(Snack.error('只有高階管理員可以修改其他管理員'))
                    return
                }

                if (adminSelf._id !== editingAdminId) {
                    await patchAdminPassword({id: editingAdminId}, {
                        password: values.password
                    })
                }
                else {
                    await patchAdminSelfPassword(values)
                }

                setSnack(Snack.success('成功儲存'))
                onClose(true)
            }
            catch (e) {
                setSnack(Snack.error('儲存失敗'))
            }
        },
        validateOnBlur: false,
        validateOnChange: false,
        validate: (values: PatchAdminPasswordData & PatchAdminSelfPasswordData) => {
            let errors = {
                ...(adminSelf && adminSelf._id === editingAdminId) && {
                    oldPassword: chainRules([requiredInputStringRule, lengthRule(6)], values.oldPassword),
                },
                password: chainRules([requiredInputStringRule, lengthRule(6)], values.password)
            }
            Object.trimLeaves(errors, [true, {}]);
            return errors;
        }
    })

    return (
        <ConfirmModal title={'修改密碼'}
                      open={open}
                      onClose={() => onClose(false)}
                      confirmButtonTitle={'儲存'}
                      confirmButtonProps={{
                          disabled: !formik.dirty || formik.isSubmitting
                      }}
                      onSubmit={formik.handleSubmit}>
            {
                (adminSelf && adminSelf._id === editingAdminId) && (
                    <TextField sx={{mt: 2}}
                               type="password"
                               name="oldPassword"
                               label="現有密碼"
                               variant="outlined"
                               size="small"
                               fullWidth={true}
                               value={formik.values.oldPassword}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               error={!!formik.errors.oldPassword && formik.touched.oldPassword}
                               helperText={formik.errors.oldPassword}
                    />
                )
            }
            <TextField sx={{mt: 2}}
                       type="password"
                       name="password"
                       label="新密碼"
                       variant="outlined"
                       size="small"
                       fullWidth={true}
                       value={formik.values.password}
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       error={!!formik.errors.password && formik.touched.password}
                       helperText={formik.errors.password}
            />
        </ConfirmModal>
    )
}