import { DbDate } from "@models/common";
import {OrderStatus} from "@models/order";

export interface MessageTemplateListInfo {
    id: string
    name: string
}

export interface TextBlock {
    id: string,
    role: "body",
    text: { text: string },
    type: "text"
}

export interface ButtonBlock {
    id: string,
    linkAction: { text: string, url: string },
    type: "button"
}

export interface MessageTemplateDetailInfo {
    id: string // version id
    projectId: string // MessageTemplateListInfo.id
    platformContent: {
        blocks: (TextBlock | ButtonBlock)[]
    }[]
    variables: {
        key: string,
        type: string,
        format: string,
        description: string,
        examplesLocale: { "zh-HK": { exampleValueStrings: string[] } }
    }[]
}

export interface WhatsappReceiver {
    studentId: string // Sub to Student_Id
    name: string // Sub to Student_Name
    phone: string
}

export const messageStatusValues = ['pending', 'sending', 'sent', 'cancelled'] as const
export type MessageStatus = typeof messageStatusValues[number]

export const messageStatusUI = {
    ['pending']: {
        title: "待發送"
    },
    ['sending']: {
        title: "發送中"
    },
    ['sent']: {
        title: "已發送"
    },
    ['cancelled']: {
        title: "已取消"
    }
}

export interface WhatsappRecord {
    _id: number

    receivers: WhatsappReceiver[]
    projectId: string
    content: string // preview
    variables: Record<string, string>

    status: MessageStatus
    sendAt?: DbDate
    createdAt: DbDate
}

export type CreateMessageRecordPayload =
    Pick<WhatsappRecord, "projectId" | "content" | "variables" | "sendAt">
    & { studentIds: string[] }
export type EditMessageRecordPayload = Partial<Pick<WhatsappRecord, "content" | "variables" | "status" | "sendAt"> & { studentIds: string[] }>
