import React, {SyntheticEvent, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {
    Box,
    Button,
    FormControlLabel,
    Grid, IconButton,
    InputAdornment,
    MenuItem,
    Paper, Stack,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import {FormikErrors, FormikTouched, useFormik} from "formik";
import {Snack, SnackbarContext} from "@/context/SnackbarContext";
import {I18nTxt, Level} from "@models/common";
import {CourseTestimonial, CourseType, CreateCoursePayload} from "@models/course";
import {courseLevelOptions, courseTypeOptions} from "@data/course";
import {chainRules, requiredInputStringRule} from "common/input-rules";
import {useNavigate, useParams} from "react-router-dom";
import {getCourse, patchCourse, PatchCourseData, postCourse} from "@/api/course";
import {TutorSelect} from "@/components/TutorSelect";
import {uploadToGoogleCloud} from "@/helpers/uploadToGoogleCloud";
import {FileUploadTextField} from "@/components/FileUploadTextField";
import {Outlet} from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {CourseLesson} from "@/routes/course-lesson";
import DeleteIcon from "@mui/icons-material/Delete";
import {CourseClassList} from "@/components/Course/CourseClassList";
import {RecommendationQuestion} from "@models/recommendation";

export default function CourseDetail() {

    const navigate = useNavigate();
    const {snack, setSnack} = useContext(SnackbarContext);
    const routeParams = useParams();
    const courseId = useMemo<number | null>(() => {
        return routeParams && routeParams.id ? Number(routeParams.id) : null
    }, [routeParams]);

    const [initialValues, setInitialValues] = useState({
        type: CourseType.OneOff,
        title: {
            zh: "",
            en: "",
        },
        description: {
            zh: "",
            en: "",
        },
        level: Level.Preschool,
        venue: {
            zh: "",
            en: "",
        },
        tutorId: "",
        videos: [] as string[],
        testimonials: [] as CourseTestimonial[],
        thumbnailUrl: "",
        leafletUrl: "",
        pricePerLesson: 0,
        discountedPricePerLesson: 0,
        isChangeClassAllowed: false, // regular
        notePages: 0, // for both types. Auto fill class when regular.
        hidden: true,
        referralApplicable: true
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        onSubmit: async (values, {setFieldValue}) => {

            const body = {...values}
            try {
                if (thumbnailFile) {
                    const publicUrl = await uploadToGoogleCloud(thumbnailFile)
                    setFieldValue("thumbnailUrl", publicUrl)
                    body.thumbnailUrl = publicUrl
                }
                if (leafletFile) {
                    const publicUrl = await uploadToGoogleCloud(leafletFile)
                    setFieldValue("leafletUrl", publicUrl)
                    body.leafletUrl = publicUrl
                }
            }
            catch (e) {
                setSnack(Snack.error('儲存失敗'))
                return
            }

            try {
                if (values.type !== CourseType.Regular) {
                    delete (body as any).isChangeClassAllowed
                }

                let cid = 0
                if (courseId) {
                    delete (body as any).type
                    await patchCourse({id: courseId}, body)
                }
                else {
                    const res = await postCourse(values)
                    cid = res.data.course._id
                }
                setSnack(Snack.success('成功儲存'))
                if (!courseId)
                    navigate(`/courses/edit/${cid}`, {
                        replace: true
                    })
            }
            catch (e) {
                setSnack(Snack.error('儲存失敗'))
            }

        },
        validateOnBlur: false,
        validateOnChange: false,
        validate: (values: CreateCoursePayload) => {
            let errors = {
                title: {
                    zh: chainRules([requiredInputStringRule], values.title.zh),
                    en: chainRules([requiredInputStringRule], values.title.en),
                },
                description: {
                    zh: chainRules([requiredInputStringRule], values.description.zh),
                    en: chainRules([requiredInputStringRule], values.description.en),
                },
                venue: {
                    zh: chainRules([requiredInputStringRule], values.venue.zh),
                    en: chainRules([requiredInputStringRule], values.venue.en),
                },
                tutorId: chainRules([requiredInputStringRule], values.tutorId),
            }
            Object.trimLeaves(errors, [true, {}])
            return errors
        }
    })

    useEffect(() => {
        if (courseId) {
            getCourse({id: courseId}).then(res => {
                const dummy = {...initialValues}
                setInitialValues(Object.assignExists(dummy, res.data.course))
                formik.resetForm()
            })
        }
    }, [])




    /**
     * Thumbnail
     * */

    const [thumbnailFile, setThumbnailFile] = useState<File | null>(null)
    const handleThumbnailFileChange = (file: File) => {
        setThumbnailFile(file)
        const url = URL.createObjectURL(file)
        formik.setFieldValue("thumbnailUrl", url)
    }

    /**
     * Leaflet
     * */

    const [leafletFile, setLeafletFile] = useState<File | null>(null)
    const handleLeafletFileChange = (file: File) => {
        setLeafletFile(file)
        const url = URL.createObjectURL(file)
        formik.setFieldValue("leafletUrl", url)
    }

    /**
     * Video
     * */

    const handleAddVideo = () => {
        formik.setFieldValue("videos", [...formik.values.videos, ""])
    }

    const onDeleteVideo = (index: number) => {
        const videos = [...formik.values.videos]
        videos.splice(index, 1)
        formik.setFieldValue("videos", videos)
    }

    /**
     * Testimonial
     * */

    const handleAddTestimonial = () => {
        formik.setFieldValue("testimonials", [...formik.values.testimonials, {
            name: { zh: "", en: "" },
            content: { zh: "", en: "" }
        }])
    }

    const onDeleteTestimonial = (index: number) => {
        const testimonials = [...formik.values.testimonials]
        testimonials.splice(index, 1)
        formik.setFieldValue("testimonials", testimonials)
    }

    const testimonialError = useCallback((idx: number) => {
        if (
          !!formik.errors.testimonials &&
          !!formik.errors.testimonials[idx]
        ) {
            const testimonialError = formik.errors.testimonials[idx] as FormikErrors<CourseTestimonial>
            return testimonialError
        }
        return null
    }, [formik])

    const testimonialTouched = useCallback((idx: number) => {
        if (
          !!formik.touched.testimonials &&
          !!formik.touched.testimonials[idx]
        ) {
            const testimonialTouched = formik.touched.testimonials[idx] as FormikTouched<CourseTestimonial>
            return testimonialTouched
        }
        return null
    }, [formik])

    /**
     * Tabs
     * */

    const [tabIndex, setTabIndex] = useState(0)

    const handleTabChange = (event: SyntheticEvent, newValue: any) => {
        setTabIndex(newValue);
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Button type="submit"
                        variant="contained"
                        disabled={!formik.dirty || formik.isSubmitting}>
                    儲存
                </Button>
                <Paper sx={{p: 4, mt: 2}}>

                    <Tabs sx={{mb: 2}} value={tabIndex} onChange={handleTabChange}>
                        <Tab label="基本資料"/>
                        <Tab label="課堂資料"/>
                    </Tabs>

                    {
                        tabIndex === 0 &&
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <TextField
                                    name="level"
                                    label="課程等級"
                                    size="small"
                                    fullWidth={true}
                                    value={formik.values.level}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={!!formik.errors.level && formik.touched.level}
                                    helperText={formik.errors.level}
                                    select>
                                    {
                                        courseLevelOptions.map(opt => (
                                            <MenuItem key={opt.title} value={opt.value}>
                                                {opt.title}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    disabled={!!courseId}
                                    name="type"
                                    label="課程類型"
                                    size="small"
                                    fullWidth={true}
                                    value={formik.values.type}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={!!formik.errors.type && formik.touched.type}
                                    helperText={formik.errors.type || '儲存後不可修改'}
                                    select>
                                    {
                                        courseTypeOptions.map(opt => (
                                            <MenuItem key={opt.title} value={opt.value}>
                                                {opt.title}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                            </Grid>
                            <Grid item xs={3}>
                                <TutorSelect
                                    name="tutorId"
                                    label="導師"
                                    size="small"
                                    fullWidth={true}
                                    value={formik.values.tutorId}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={!!formik.errors.tutorId && formik.touched.tutorId}
                                    helperText={formik.errors.tutorId}/>
                            </Grid>
                            <Grid item xs={3}></Grid>

                            <Grid item xs={3}>
                                <FormControlLabel
                                    control={
                                        <Switch name="hidden"
                                                checked={formik.values.hidden}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}/>
                                    }
                                    label="隱藏課程" />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControlLabel
                                    control={
                                        <Switch name="referralApplicable"
                                                checked={formik.values.referralApplicable}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}/>
                                    }
                                    label="可推薦" />
                            </Grid>
                            <Grid item xs={6}></Grid>

                            <Grid item xs={6}>
                                <TextField name="title.zh"
                                           label="中文標題"
                                           size="small"
                                           fullWidth={true}
                                           value={formik.values.title.zh}
                                           onChange={formik.handleChange}
                                           onBlur={formik.handleBlur}
                                           error={!!formik.errors.title?.zh && formik.touched.title?.zh}
                                           helperText={formik.errors.title?.zh}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField name="title.en"
                                           label="英文標題"
                                           size="small"
                                           fullWidth={true}
                                           value={formik.values.title.en}
                                           onChange={formik.handleChange}
                                           onBlur={formik.handleBlur}
                                           error={!!formik.errors.title?.en && formik.touched.title?.en}
                                           helperText={formik.errors.title?.en}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField multiline
                                           rows={6}
                                           name="description.zh"
                                           label="中文描述"
                                           size="small"
                                           fullWidth={true}
                                           value={formik.values.description.zh}
                                           onChange={formik.handleChange}
                                           onBlur={formik.handleBlur}
                                           error={!!formik.errors.description?.zh && formik.touched.description?.zh}
                                           helperText={formik.errors.description?.zh}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField multiline
                                           rows={6}
                                           name="description.en"
                                           label="英文描述"
                                           size="small"
                                           fullWidth={true}
                                           value={formik.values.description.en}
                                           onChange={formik.handleChange}
                                           onBlur={formik.handleBlur}
                                           error={!!formik.errors.description?.en && formik.touched.description?.en}
                                           helperText={formik.errors.description?.en}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField name="venue.zh"
                                           label="中文地址"
                                           size="small"
                                           fullWidth={true}
                                           value={formik.values.venue.zh}
                                           onChange={formik.handleChange}
                                           onBlur={formik.handleBlur}
                                           error={!!formik.errors.venue?.zh && formik.touched.venue?.zh}
                                           helperText={formik.errors.venue?.zh}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField name="venue.en"
                                           label="英文地址"
                                           size="small"
                                           fullWidth={true}
                                           value={formik.values.venue.en}
                                           onChange={formik.handleChange}
                                           onBlur={formik.handleBlur}
                                           error={!!formik.errors.venue?.en && formik.touched.venue?.en}
                                           helperText={formik.errors.venue?.en}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <FileUploadTextField
                                    textFieldProps={{
                                        name: "thumbnailUrl",
                                        label: "縮圖URL",
                                        fullWidth: true,
                                        value: formik.values.thumbnailUrl,
                                        onChange: formik.handleChange,
                                        onBlur: formik.handleBlur,
                                        error: !!formik.errors.thumbnailUrl && formik.touched.thumbnailUrl,
                                        helperText: formik.errors.thumbnailUrl,
                                        // readOnly
                                    }}
                                    accept={"image/*"}
                                    change={handleThumbnailFileChange}
                                />
                                { formik.values.thumbnailUrl &&
                                    <Box sx={{mt: 2}}>
                                        <img style={{width: "100%"}} src={formik.values.thumbnailUrl} />
                                    </Box>
                                }
                            </Grid>
                            <Grid item xs={6}>
                                <FileUploadTextField
                                    textFieldProps={{
                                        name: "leafletUrl",
                                        label: "傳單URL",
                                        fullWidth: true,
                                        value: formik.values.leafletUrl,
                                        onChange: formik.handleChange,
                                        onBlur: formik.handleBlur,
                                        error: !!formik.errors.leafletUrl && formik.touched.leafletUrl,
                                        helperText: formik.errors.leafletUrl
                                    }}
                                    accept={"*/*"}
                                    change={handleLeafletFileChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField type="number"
                                           name="pricePerLesson"
                                           label="每堂原價"
                                           size="small"
                                           fullWidth={true}
                                           value={formik.values.pricePerLesson}
                                           onChange={formik.handleChange}
                                           onBlur={formik.handleBlur}
                                           error={!!formik.errors.pricePerLesson && formik.touched.pricePerLesson}
                                           helperText={formik.errors.pricePerLesson}
                                           InputProps={{
                                               startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                           }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField type="number"
                                           name="discountedPricePerLesson"
                                           label="每堂售價"
                                           size="small"
                                           fullWidth={true}
                                           value={formik.values.discountedPricePerLesson}
                                           onChange={formik.handleChange}
                                           onBlur={formik.handleBlur}
                                           error={!!formik.errors.discountedPricePerLesson && formik.touched.discountedPricePerLesson}
                                           helperText={formik.errors.discountedPricePerLesson}
                                           InputProps={{
                                               startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                           }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField type="number"
                                           name="notePages"
                                           label="筆記頁數"
                                           size="small"
                                           fullWidth={true}
                                           value={formik.values.notePages}
                                           onChange={formik.handleChange}
                                           onBlur={formik.handleBlur}
                                           error={!!formik.errors.notePages && formik.touched.notePages}
                                           helperText={formik.errors.notePages}
                                />
                            </Grid>

                            {
                                formik.values.type === CourseType.Regular && (
                                    <Grid item xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch name="isChangeClassAllowed"
                                                        checked={formik.values.isChangeClassAllowed}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}/>
                                            }
                                            label="可調班" />
                                    </Grid>
                                )
                            }

                            <Grid item xs={12}>
                                <Typography
                                    component='div'
                                    sx={{ mt: 2 }}
                                >
                                    YouTube介绍影片
                                </Typography>
                                {
                                    formik.values.videos.map((v, idx) => (
                                        <Stack sx={{ mt: 2 }}
                                               spacing={{ xs: 1, sm: 2 }}
                                               justifyContent="space-between"
                                               alignItems="center"
                                               direction="row" useFlexGap flexWrap="wrap">
                                            <TextField sx={{flex: 1}}
                                                       name={`videos.${idx}`}
                                                       label="影片ID"
                                                       size="small"
                                                       value={formik.values.videos[idx]}
                                                       onChange={formik.handleChange}
                                                       onBlur={formik.handleBlur}
                                                       error={!!formik.errors.videos && !!formik.touched.videos && !!formik.errors.videos[idx] && formik.touched.videos[idx]}
                                                       helperText={!!formik.errors.videos && formik.errors.videos[idx]}
                                            />
                                            <IconButton
                                                color='error'
                                                size='small'
                                                onClick={() => onDeleteVideo(idx)}
                                                sx={{ ml: 0.5 }}
                                            >
                                                <DeleteIcon></DeleteIcon>
                                            </IconButton>
                                        </Stack>
                                    ))
                                }
                                <Button sx={{ mt: 2 }}
                                        variant="contained"
                                        fullWidth={true}
                                        onClick={handleAddVideo}>
                                    新增影片
                                </Button>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography
                                  component='div'
                                  sx={{ mt: 2 }}
                                >
                                    學生好評
                                </Typography>
                                {
                                    formik.values.testimonials && formik.values.testimonials.map((t, idx) => (
                                      <Stack sx={{ mt: 2 }}
                                             spacing={{ xs: 1, sm: 2 }}
                                             justifyContent="space-between"
                                             alignItems="center"
                                             direction="row"
                                             useFlexGap
                                             flexWrap="wrap">
                                          <TextField sx={{flex: 1}}
                                                     name={`testimonials.${idx}.name.zh`}
                                                     label="中文名字"
                                                     size="small"
                                                     value={formik.values.testimonials[idx].name.zh}
                                                     onChange={formik.handleChange}
                                                     onBlur={formik.handleBlur}
                                                     error={!!testimonialTouched(idx)?.name?.zh && !!testimonialError(idx)?.name?.zh}
                                                     helperText={testimonialError(idx)?.name?.zh || ""}
                                          />
                                          <TextField sx={{flex: 1}}
                                                     name={`testimonials.${idx}.name.en`}
                                                     label="英文名字"
                                                     size="small"
                                                     value={formik.values.testimonials[idx].name.en}
                                                     onChange={formik.handleChange}
                                                     onBlur={formik.handleBlur}
                                                     error={!!testimonialTouched(idx)?.name?.en && !!testimonialError(idx)?.name?.en}
                                                     helperText={testimonialError(idx)?.name?.en || ""}
                                          />
                                          <TextField sx={{flex: 1}}
                                                     name={`testimonials.${idx}.content.zh`}
                                                     label="中文内容"
                                                     size="small"
                                                     value={formik.values.testimonials[idx].content.zh}
                                                     onChange={formik.handleChange}
                                                     onBlur={formik.handleBlur}
                                                     error={!!testimonialTouched(idx)?.content?.zh && !!testimonialError(idx)?.content?.zh}
                                                     helperText={testimonialError(idx)?.content?.zh || ""}
                                          />
                                          <TextField sx={{flex: 1}}
                                                     name={`testimonials.${idx}.content.en`}
                                                     label="英文内容"
                                                     size="small"
                                                     value={formik.values.testimonials[idx].content.en}
                                                     onChange={formik.handleChange}
                                                     onBlur={formik.handleBlur}
                                                     error={!!testimonialTouched(idx)?.content?.en && !!testimonialError(idx)?.content?.en}
                                                     helperText={testimonialError(idx)?.content?.en || ""}
                                          />
                                          <IconButton
                                            color='error'
                                            size='small'
                                            onClick={() => onDeleteTestimonial(idx)}
                                            sx={{ ml: 0.5 }}
                                          >
                                              <DeleteIcon></DeleteIcon>
                                          </IconButton>
                                      </Stack>
                                    ))
                                }
                                <Button sx={{ mt: 2 }}
                                        variant="contained"
                                        fullWidth={true}
                                        onClick={handleAddTestimonial}>
                                    新增評價
                                </Button>
                            </Grid>
                        </Grid>
                    }

                    {
                        tabIndex === 1 && courseId && (
                            formik.values.type === CourseType.OneOff ?
                                <CourseLesson courseId={courseId} /> :
                                <CourseClassList courseId={courseId} />
                        )
                    }

                </Paper>
            </form>
        </>
    )
}
