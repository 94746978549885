import React, {FC, useEffect, useMemo, useState} from "react";
import {Autocomplete, TextField} from "@mui/material";
import {TextFieldProps, TextFieldVariants} from "@mui/material/TextField/TextField";
import {getProducts, GetProductsParams} from "@/api/product";
import {useQuery} from "react-query";
import {QUERY_KEY} from "@/data/query-key";
import {Product} from "@models/product";
import {AutocompleteProps} from "@mui/material/Autocomplete/Autocomplete";
import debounce from 'debounce';
import {Pagination} from "common/models/pagination";

export type ProductSelectProps = Omit<AutocompleteProps<string | { id: string; label: string; }, false, false, true>, 'renderInput' | 'options'> & {
    textFieldProps: {
        variant?: TextFieldVariants;
    } & Omit<TextFieldProps, 'variant'>
};

export const ProductSelect: FC<ProductSelectProps> = (props) => {

    const {
        textFieldProps,
        ...autocompleteProps
    } = props

    const [keyword, setKeyword] = useState<string | null>(null)
    const [productIds, setProductIds] = useState<string[] | null>(null)

    const query: GetProductsParams & Partial<Pagination> = {
        ...(!!keyword && {keyword}),
        ...(!!productIds && {productIds})
    }

    const { data, refetch, isLoading } = useQuery(
        [QUERY_KEY.PRODUCT_LIST, query],
        async () => {
            if (!Object.values(query).length)
                return {
                    products: []
                }
            return (await getProducts(query)).data
        }
    )

    const [productsLookup, setProductsLookup] = useState<{[key:string]: Product}>({})
    useEffect(() => {
        setProductsLookup({
            ...productsLookup,
            ...(data && data.products.reduce((l, p) => ({
                ...l,
                [String(p?._id || -1)]: p
            }), {}))
        })
    }, [data]);

    const options = useMemo(() => {
        const opts: string[] = []
        const value = String(autocompleteProps.value)
        if (value)
            opts.push(value)
        if (!keyword)
            return opts
        return [
            ...opts,
            ...Object.values(productsLookup).map((product) => String(product._id))
        ]
    }, [productsLookup, autocompleteProps])

    useEffect(() => {
        if (autocompleteProps.value)
            setProductIds([String(autocompleteProps.value)])
        else
            setProductIds(null)
    }, [autocompleteProps.value])

    const inputChanged = debounce((newInputValue) => setKeyword(newInputValue), 500);

    return (
        <Autocomplete
            {...autocompleteProps}
            autoComplete
            includeInputInList
            filterSelectedOptions
            noOptionsText="沒有相關產品"
            onInputChange={(event, newInputValue) => {
                inputChanged(newInputValue)
            }}
            getOptionLabel={(option) => {
                const product = productsLookup[typeof option === 'string' ? option : option.id]
                return product ? `[${product._id}] ${product.title.zh}` : ''
            }}
            options={options}
            renderInput={(params) =>
                <TextField {...params} {...textFieldProps} placeholder={'輸入產品名稱以搜尋'} />
            }
        />
    )
}
