import {Box, Button, FormControlLabel, IconButton, Switch, TextField} from "@mui/material";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Snack, SnackbarContext} from "@/context/SnackbarContext";
import DeleteIcon from "@mui/icons-material/Delete";
import {getProductCategories, patchProductCategory, postProductCategory, PostProductCategoryBody} from "@/api/product";
import {ConfirmModal} from "@/components/ConfirmModal";
import {useFormik} from "formik";
import {chainRules, requiredInputStringRule} from "common/input-rules";
import {constructInit} from "@/helpers/contructInit";
import EditIcon from "@mui/icons-material/Edit";
import {ProductCategory} from "@models/product";
import {useQuery} from "react-query";
import {QUERY_KEY} from "@/data/query-key";
import DoneIcon from "@mui/icons-material/Done";

type GridProductCategory = ProductCategory & {
    id: string
}

export default function ProductCategoryList() {

    const { snack, setSnack } = useContext(SnackbarContext);

    const columns: GridColDef<GridProductCategory>[] = [
        {
            field: 'title',
            headerName: '標題',
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => {
                return (
                    <Box>
                        { row.title.zh }
                    </Box>
                )
            }
        },
        {
            field: 'hidden',
            headerName: '已隱藏',
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => {
                return (
                    row.hidden ? <DoneIcon /> : <></>
                )
            }
        },
        {
            field: "action",
            headerName: "行動",
            sortable: false,
            minWidth: 120,
            renderCell: ({ row }) => {
                return (
                    <Box sx={{ display: "flex", justifyContent: "end", width: "100%" }}>
                        <IconButton
                            color='primary' size='small'
                            onClick={() => handleOpenModal(row)}
                            sx={{ ml: 0.5 }}
                        >
                            <EditIcon></EditIcon>
                        </IconButton>
                        {/*<IconButton*/}
                        {/*    color='error'*/}
                        {/*    size='small'*/}
                        {/*    onClick={() => clickDelete(row)}*/}
                        {/*    sx={{ ml: 0.5 }}*/}
                        {/*>*/}
                        {/*    <DeleteIcon></DeleteIcon>*/}
                        {/*</IconButton>*/}
                    </Box>
                );
            },
        },
    ];

    /**
     * Init
     * */

    const { data, refetch, isLoading } = useQuery(
        [QUERY_KEY.PRODUCT_CATEGORY_LIST],
        async () => {
            return (await getProductCategories()).data
        }
    )

    const productCategoryList = useMemo<GridProductCategory[]>(() => {
        if (!data)
            return []
        return data.productCategories.map(c => ({
            id: String(c._id || ""),
            ...c
        }))
    }, [data])

    /***
     * Row actions
     */

    const clickDelete = (row: GridProductCategory) => {
        // setProductCategoryList(productCategoryList.filter(h => h.id !== row.id))
    }

    const clickSave = async () => {
        try {
            // await setProductCategorys({
            //     productCategorys: productCategoryList.map(h => h.date)
            // });
            setSnack(Snack.success('成功儲存'));
            // await refetch()
        }
        catch (e) {
            setSnack(Snack.error('儲存失敗'));
        }
    }

    /***
     * Add ProductCategory Modal
     */

    const emptyProductCategoryRequired = {
        title: { zh: "", en: "" },
        hidden: false
    }

    const emptyProductCategoryOptional = {}

    const [initialValues, setInitialValues] = useState({
        ...emptyProductCategoryRequired
    });

    const formik = useFormik<PostProductCategoryBody>({
        enableReinitialize: true,
        initialValues: initialValues,
        onSubmit: async (values) => {
            try {
                if (!!editingProductCategory) {
                    await patchProductCategory({ productCategoryId: editingProductCategory.id }, values)
                }
                else {
                    await postProductCategory(values)
                }
                setSnack(Snack.success('成功儲存'))
                handleCloseModal()
                refetch()
            }
            catch (e) {
                setSnack(Snack.error('儲存失敗'))
            }
        },
        validateOnBlur: false,
        validateOnChange: false,
        validate: values => {
            let errors = {
                title: {
                    zh: chainRules([requiredInputStringRule], values.title.zh),
                    en: chainRules([requiredInputStringRule], values.title.en),
                },
            }
            Object.trimLeaves(errors, [true, {}]);
            return errors;
        }
    })
    const [editingProductCategory, setEditingProductCategory] = useState<GridProductCategory | null>(null)
    const [openModal, setOpenModal] = useState(false)
    const handleOpenModal = useCallback((category?: GridProductCategory) => {
        setEditingProductCategory(category || null)
        setInitialValues(constructInit(emptyProductCategoryRequired, emptyProductCategoryOptional, category))
        formik.resetForm()
        setOpenModal(true)
    }, [formik])
    const handleCloseModal = () => setOpenModal(false)

    return (
        <>
            {/*<Button variant="contained" onClick={clickSave}>*/}
            {/*    儲存*/}
            {/*</Button>*/}

            <DataGrid
                sx={{
                    mt: 2,
                    backgroundColor: "white",
                }}
                // loading={isLoading}
                rows={productCategoryList}
                columns={columns}
                getRowId={(row) => (row.id || "").toString()}

                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableRowSelectionOnClick

                // checkboxSelection
                // onRowSelectionModelChange={handleRowSelectionChange}
            />

            <Box>
                <Button sx={{ mt: 2 }}
                        variant="contained"
                        fullWidth={true}
                        onClick={() => handleOpenModal()}>
                    新增分類
                </Button>
            </Box>

            {/* Modal */}
            <ConfirmModal title={`新增分類`}
                          open={openModal}
                          onClose={handleCloseModal}
                          confirmButtonProps={{
                              disabled: !formik.dirty || formik.isSubmitting
                          }}
                          onSubmit={formik.handleSubmit}>
                <TextField sx={{mt: 2}}
                           name="title.zh"
                           label="中文標題"
                           size="small"
                           fullWidth={true}
                           value={formik.values.title.zh}
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           error={!!formik.errors.title?.zh && formik.touched.title?.zh}
                           helperText={formik.errors.title?.zh}
                />
                <TextField sx={{mt: 2}}
                           name="title.en"
                           label="英文標題"
                           size="small"
                           fullWidth={true}
                           value={formik.values.title.en}
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           error={!!formik.errors.title?.en && formik.touched.title?.en}
                           helperText={formik.errors.title?.en}
                />
                <FormControlLabel
                    sx={{mt: 2}}
                    control={
                        <Switch name="hidden"
                                checked={formik.values.hidden}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}/>
                    }
                    label="隱藏分類" />
            </ConfirmModal>
        </>
    );
}
