import React, {FC, useState} from "react";
import {TextField} from "@mui/material";
import {TextFieldProps, TextFieldVariants} from "@mui/material/TextField/TextField";

export interface FileUploadTextFieldProps {

    textFieldProps: {
        variant?: TextFieldVariants;
    } & Omit<TextFieldProps, 'variant'>,

    change: (file: File) => void

    accept?: string
}

export const FileUploadTextField: FC<FileUploadTextFieldProps> = (props) => {

    const {
        textFieldProps,
        change,
        accept,
    } = props

    const [file, setFile] = useState<File | null>(null)
    const handleFileOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const files = Array.from(e.target.files)
            change(files[0])
        }
    }

    return (
        <TextField
            size="small"
            {...textFieldProps}
            InputProps={{
                endAdornment: (
                    <input
                        // ref={ inputRef }
                        type="file"
                        accept={accept}
                        onChange={ handleFileOnChange }
                        tabIndex={ -1 }
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            opacity: 0,
                        }} />
                ),
            }} />
    )
}