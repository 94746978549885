import {DataGrid, GridColDef} from "@mui/x-data-grid";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {SnackbarContext} from "@/context/SnackbarContext";
import {getUnpaidStudents} from "@/api/order";
import {UnpaidRecord} from "@models/order";

type GridUnpaidStudent = {
    id: string,
} & UnpaidRecord

export default function UnpaidStudentList() {

    const { snack, setSnack } = useContext(SnackbarContext);

    const [unpaidStudentList, setUnpaidStudentList] = useState<GridUnpaidStudent[]>([])

    const columns: GridColDef<GridUnpaidStudent>[] = [
        // {
        //     field: "id",
        //     headerName: "日期",
        //     sortable: false,
        //     flex: 1,
        //     minWidth: 200,
        // },
        {
            field: "studentId",
            headerName: "學生ID",
            sortable: false,
            flex: 1,
            minWidth: 200,
        },
        {
            field: "studentName",
            headerName: "學生名稱",
            sortable: false,
            flex: 1,
            minWidth: 200,
        },
        {
            field: "courseId",
            headerName: "課程ID",
            sortable: false,
            flex: 1,
            minWidth: 200,
        },
        {
            field: "courseTitle",
            headerName: "課程標題",
            sortable: false,
            flex: 1,
            minWidth: 200,
        },
        {
            field: "month",
            headerName: "月份",
            sortable: false,
            flex: 1,
            minWidth: 200,
        },

        // {
        //     field: "action",
        //     headerName: "行動",
        //     sortable: false,
        //     minWidth: 120,
        //     renderCell: ({ row }) => {
        //         return (
        //             <Box sx={{ display: "flex", justifyContent: "end", width: "100%" }}>
        //                 <IconButton
        //                     color='error'
        //                     size='small'
        //                     onClick={() => clickDelete(row)}
        //                     sx={{ ml: 0.5 }}
        //                 >
        //                     <DeleteIcon></DeleteIcon>
        //                 </IconButton>
        //             </Box>
        //         );
        //     },
        // },
    ];

    /**
     * Init
     * */

    useEffect(() => {
        getUnpaidStudents().then(res => {
            setUnpaidStudentList(res.data.unpaidStudents.map(r => ({
                id: r.studentId,
                ...r
            })))
        })
    }, [])

    // /***
    //  * Row actions
    //  */
    //
    // const clickDelete = (row: GridUnpaidStudent) => {
    //     setUnpaidStudentList(unpaidStudentList.filter(h => h.id !== row.id))
    // }

    return (
        <>
            <DataGrid
                sx={{
                    mt: 2,
                    backgroundColor: "white",
                }}
                // loading={isLoading}
                rows={unpaidStudentList}
                columns={columns}
                getRowId={(row) => (row.id || "").toString()}

                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableRowSelectionOnClick
            />
        </>
    );
}
