import { DbDate, DbObjectId, I18nTxt, Level } from "./common";
import { PrivateFile } from "@models/file";
import { Homework, StudentHomework } from "@models/homework";

export enum CourseType {
    Regular,
    OneOff
}

export interface CourseTestimonial {
    name: I18nTxt
    content: I18nTxt
}

export interface Course {
    _id: number

    type: CourseType

    title: I18nTxt
    description: I18nTxt
    level: Level
    venue: I18nTxt

    tutorId: string

    videos: string[]
    testimonials?: CourseTestimonial[]

    thumbnailUrl: string
    leafletUrl: string

    pricePerLesson: number
    discountedPricePerLesson: number

    isChangeClassAllowed?: boolean // regular
    notePages: number // for both types. Auto fill class when regular
    hidden: boolean
    referralApplicable: boolean

    lessonCount?: number // oneoff

    createdAt: DbDate
}

export type CreateCoursePayload = Omit<Course, "_id" | "lessonCount" | "createdAt">;
export type UpdateCoursePayload = Partial<Omit<Course, "_id" | "lessonCount" | "createdAt" | "type">>;

export interface CourseFilter {
    keyword?: string
    type?: CourseType
    level?: Level
    courseIds?: string // 1,2,3
}

export interface WebCourseFilter {
    keyword?: string
    type?: CourseType
    level?: Level
    sort?: "" | "date" | "price"
    courseIds?: string // 1,2,3
}

export interface RegularClassDef {
    _id?: DbObjectId
    courseId: number
    classId: number

    tutorId: string
    venue: I18nTxt
    weekday: number // follow dayjs definition
    start: DbDate // takes hour & minutes only
    end: DbDate  // takes hour & minutes only

    quota: number
    notePages: number
}

export type CreateClassDefPayload =
    Omit<RegularClassDef, "_id" | "courseId" | "classId" | "tutorId">
    & Partial<Pick<RegularClassDef, "tutorId" | "venue">>;
export type UpdateClassDefPayload = Partial<Omit<RegularClassDef, "_id" | "courseId" | "classId">>;

// Auto gen each month
export interface RegularClassInstance extends RegularClassDef {
    month: string // YYYY/MM
    dates: string[] // YYYY-MM-DD
    enrolled?: number
    reserved?: boolean
}

export const REGULAR_CLASS_MONTH_FORMAT = "YYYY/MM";
export const REGULAR_CLASS_DATE_FORMAT = "YYYY-MM-DD";

export interface ListClassInstancesQuery {
    classId?: number,
    fromMonth?: string,
    toMonth?: string,
}

export type ClassParam = Pick<RegularClassInstance, "courseId" | "classId" | "month">

export type UpdateClassInstancePayload = Partial<Omit<RegularClassInstance, "_id" | "courseId" | "classId" | "month">>;

// Auto gen each month
export interface RegularLesson {
    _id: DbObjectId
    courseId: number
    classId: number
    month: string
    date: string

    tutorId: string

    start: DbDate
    end: DbDate
}

export type RegularLessonParam = Pick<RegularLesson, "courseId" | "classId" | "month" | "date">
export type UpdateRegularLessonPayload = Pick<RegularLesson, "tutorId">

export interface ListRegularLessonsQuery {
    fromMonth?: string,
    toMonth?: string,
}

export interface OneOffLesson {
    _id: DbObjectId
    courseId: number
    lessonId: number

    tutorId: string

    timeslots: OneOffLessonTimeslot[]
}

export interface OneOffLessonTimeslot {
    id: number
    tutorId: string
    start: DbDate
    end: DbDate
}

export type CreateOneOffLessonPayload = Omit<OneOffLesson, "_id" | "courseId" | "lessonId">;
export type UpdateOneOffLessonPayload = Partial<Omit<OneOffLesson, "_id" | "courseId" | "lessonId" | "timeslots"> & { timeslots: Omit<OneOffLessonTimeslot, "id">[] }>;


export interface Holiday {
    _id: DbObjectId
    date: string // YYYYMMDD
}

export interface YoutubeTitle {
    _id: string
    title: string
    lastFetch: DbDate
}

export interface WebCourseRes {
    course: Course,
    videoTitles: Record<string, string>,
    regularClasses?: (RegularClassInstance & { enrolled: number })[],
    regularLessons?: RegularLesson[],
    oneOffLessons?: OneOffLesson[],
}

export interface WebOrderCourseRes {
    course: Course,
    regularClass?: RegularClassInstance | null,
    regularLessons?: RegularLesson[],
    oneOffLessons?: OneOffLesson[],
}

export type OneOffTimeslot = Omit<OneOffLesson, "timeslots"> & { timeslot: OneOffLessonTimeslot }

export type _WebPortalCourse = Course & {
    regularClasses?: RegularClassInstance[],
    regularLessons?: RegularLesson[],
    oneOffTimeslots?: OneOffTimeslot[],
    homework?: Homework
}

export type WebPortalCourse = {
    courseId: number
    title: I18nTxt,
    thumbnailUrl: string
    type: CourseType
    regularClass?: RegularClassInstance
    regularLessons?: RegularLesson[]
    oneOffTimeslots?: OneOffLessonTimeslot[]
    homework?: Homework
    homeworks: StudentHomework[]
    nextLessonStartTimestamp: number
    nextLessonEndTimestamp?: number
    upcoming: boolean
    upcomingLabel?: string
    monthLabel?: string
}

export interface WebPortalCourseLesson {
    courseId: number
    date: string
    start: string
    end: string
    timestamp: number
}

export interface WebPortalCourseRes {
    upcoming: WebPortalCourse[],
    courses: WebPortalCourse[],
    lessons: WebPortalCourseLesson[],
    files: Record<string, PrivateFile>
}

export interface TutorLessonItemBase {
    courseId: number
    type: CourseType
    title: I18nTxt
    thumbnailUrl: string
    timestamp: number // for sorting & show leave req btn

    date: string // same as regular
    start: string
    end: string
}

export interface TutorRegularLessonItem extends TutorLessonItemBase {
    type: CourseType.Regular
    regularLessonId?: DbObjectId
    classId: number
    month: string
}

export interface TutorOneoffLessonItem extends TutorLessonItemBase {
    type: CourseType.OneOff
    oneOffLessonId?: DbObjectId
    lessonId: number
    timeslotId: number
}

export type TutorLessonItem = TutorRegularLessonItem | TutorOneoffLessonItem

export interface TutorLessonMonth {
    month: string
    since: string
    until: string
}

export interface AttendanceLessonData {
    title: I18nTxt

    date: string // same as regular
    start: string
    end: string
}
