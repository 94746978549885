import { DbDate, DbObjectId, I18nTxt } from "@models/common";
import { Course, OneOffLesson, RegularLesson } from "@models/course";
import {OrderStatus} from "@models/order";

export interface Tutor {
    _id: string // TUXXXXXX
    name: string
    phone: string; // e.164
    email: string

    createdAt: DbDate
}

export type CreateTutorPayload = Omit<Tutor, "_id" | "createdAt"> & { password: string };
export type UpdateTutorPayload = Partial<Pick<Tutor, "name" | "phone" | "email">>

export interface TutorFilter {
    keyword?: string
}

export enum TutorLeaveRequestStatus {
    Pending,
    Accepted,
    Rejected,
    Cancelled,
}

export interface TutorLeaveRequest {
    _id: number
    tutorId: string
    message: string
    status: TutorLeaveRequestStatus

    regularLessonId?: DbObjectId
    oneOffLessonId?: DbObjectId
    oneOffTimeslotId?: number
    subTutorId?: string

    notiSent: boolean
    createdAt: DbDate
}

export type CreateTutorLeavePayload = Pick<TutorLeaveRequest, "message" | "regularLessonId" | "oneOffLessonId" | "oneOffTimeslotId">;

export interface GetTutorLeaveRequestResponse {
    tutorLeaveRequest: TutorLeaveRequest
    regularLesson: RegularLesson | null
    oneOffLesson: OneOffLesson | null
    course: Course | null
}

export interface TutorStudentDashboardFilter {
    since?: string // Order.timeslots.month
    until?: string // Order.timeslots.month
}

export interface TutorLeaveItem {
    uid: string
    _id: DbObjectId

    classId?: number
    lessonId?: number
    timeslotId?: number

    date: string
    start: string
    end: string

    timestamp: number
}

export interface WebTutorLeaveRequest extends TutorLeaveRequest {
    title: I18nTxt
    date: string
    start: string
    end: string
}

export const tutorLeaveRequestStatusUI = {
    [TutorLeaveRequestStatus.Pending]: {
        title: "待審核",
    },
    [TutorLeaveRequestStatus.Accepted]: {
        title: "已批准",
    },
    [TutorLeaveRequestStatus.Rejected]: {
        title: "已拒絕",
    },
    [TutorLeaveRequestStatus.Cancelled]: {
        title: "已取消",
    },
};