import React, {FC, useMemo, useState} from "react";
import {MenuItem, TextField} from "@mui/material";
import {TextFieldProps, TextFieldVariants} from "@mui/material/TextField/TextField";
import {getCourses} from "@/api/course";
import {useQuery} from "react-query";
import {QUERY_KEY} from "@/data/query-key";
import {Course} from "@models/course";

export type CourseSelectProps = {
    allowedCourseIds?: number[];
    variant?: TextFieldVariants;
    onCourseChanged?: (course?: Course) => void;
} & Omit<TextFieldProps, 'variant'>

export const CourseSelect: FC<CourseSelectProps> = (props) => {

    const {
        allowedCourseIds,
        onCourseChanged,
        onChange,
        ...textFieldProps
    } = props

    const [keyword, setKeyword] = useState<string | null>(null)

    const query = {
        keyword,
    }

    const { data, refetch, isLoading } = useQuery(
        [QUERY_KEY.COURSE_LIST, query],
        async () => {
            return (await getCourses({
                ...(!!keyword && {keyword})
            })).data
        }
    )

    const courseList = useMemo(() => {
        if (!data)
            return []

        let courses = data.courses.map(u => ({
            id: u._id, // Add id for MUI
            ...u
        }))
        if (!!allowedCourseIds)
            courses = courses.filter(c => allowedCourseIds.includes(c._id))
        return courses
    }, [data, allowedCourseIds])

    return (
        <TextField
            {...textFieldProps}
            onChange={(...params) => {
                const courseId = params[0].target.value
                if (!!onCourseChanged)
                    onCourseChanged(courseList.find(c => c._id === Number(courseId)))
                if (!!onChange)
                    onChange(...params)
            }}
            select>
            {
                courseList.map(course => (
                    <MenuItem key={course._id} value={course._id}>
                        {`[${course._id}] - ${course.title.zh}`}
                    </MenuItem>
                ))
            }
        </TextField>
    )
}
