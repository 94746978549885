import React, {FC, useContext, useEffect} from "react";
import {useFormik} from "formik";
import {patchAdmins, patchAdminSelf, postAdmins} from "@/api/admin";
import {Snack, SnackbarContext} from "@/context/SnackbarContext";
import {chainRules, formatEmailRule, requiredInputStringRule} from "common/input-rules";
import {TextField} from "@mui/material";
import {Admin} from "@models/admin";
import {ConfirmModal} from "@/components/ConfirmModal";
import {useAuthStore} from "@/hooks/useAuthStore";

export type AdminEditDetailsModalProps = {
    open: boolean
    onClose: (saved: boolean) => void

    editingAdmin: Admin | null
}

export const AdminEditDetailsModal: FC<AdminEditDetailsModalProps> = (props) => {

    const {
        open,
        onClose,

        editingAdmin
    } = props

    const {snack, setSnack} = useContext(SnackbarContext)

    const {
        adminSelf
    } = useAuthStore()

    useEffect(() => {
        if (open) {
            formik.resetForm()
            if (editingAdmin)
                formik.setValues({
                    name: editingAdmin.name,
                    email: editingAdmin.email,
                    password: ""
                })
        }
    }, [open])

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            password: ""
        },
        onSubmit: async (values) => {
            try {
                if (!adminSelf)
                    return

                if (!adminSelf.superAdmin && !editingAdmin) {
                    setSnack(Snack.error('只有高階管理員可以新增管理員'))
                    return
                }

                if (!adminSelf.superAdmin && editingAdmin && adminSelf._id !== editingAdmin._id) {
                    setSnack(Snack.error('只有高階管理員可以修改其他管理員'))
                    return
                }

                if (editingAdmin && editingAdmin._id) {
                    if (adminSelf._id !== editingAdmin._id) {
                        await patchAdmins({id: editingAdmin._id}, {
                            name: values.name,
                            email: values.email,
                        })
                    }
                    else {
                        await patchAdminSelf({
                            name: values.name,
                            email: values.email,
                        })
                    }
                }
                else {
                    await postAdmins(values)
                }

                setSnack(Snack.success('成功儲存'))
                onClose(true)
            }
            catch (e) {
                setSnack(Snack.error('儲存失敗'))
            }
        },
        validateOnBlur: false,
        validateOnChange: false,
        validate: (values: { name: string; email: string; password: string }) => {
            let errors = {
                name: chainRules([requiredInputStringRule], values.name),
                email: chainRules([requiredInputStringRule, formatEmailRule], values.email),
                ...(!editingAdmin && {
                    password: chainRules([requiredInputStringRule], values.password)
                })
            };
            Object.trimLeaves(errors, [true, {}])
            return errors
        }
    })

    return (
        <ConfirmModal title={`${editingAdmin && editingAdmin._id ? '修改' : '新增'}管理員`}
                      open={open}
                      onClose={() => onClose(false)}
                      confirmButtonTitle={'儲存'}
                      confirmButtonProps={{
                          disabled: !formik.dirty || formik.isSubmitting
                      }}
                      onSubmit={formik.handleSubmit}>
            <TextField sx={{mt: 2}}
                       id="name"
                       label="名稱"
                       variant="outlined"
                       size="small"
                       fullWidth={true}
                       value={formik.values.name}
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       error={!!formik.errors.name && formik.touched.name}
                       helperText={formik.errors.name}
            />
            <TextField sx={{mt: 2}}
                       type="email"
                       id="email"
                       label="電郵"
                       variant="outlined"
                       size="small"
                       fullWidth={true}
                       value={formik.values.email}
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       error={!!formik.errors.email && formik.touched.email}
                       helperText={formik.errors.email}
            />
            { !editingAdmin && (
                <TextField sx={{mt: 2}}
                           type="password"
                           id="password"
                           label="密碼"
                           variant="outlined"
                           size="small"
                           fullWidth={true}
                           value={formik.values.password}
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           error={!!formik.errors.password && formik.touched.password}
                           helperText={formik.errors.password}
                />
            )}
        </ConfirmModal>
    )
}