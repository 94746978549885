import React, {useContext, useEffect, useMemo, useState} from "react";
import {Snack, SnackbarContext} from "@/context/SnackbarContext";
import {
    Card,
    Button,
    Grid,
    Paper,
    TextField,
    InputLabel, Switch, FormControlLabel, Box
} from "@mui/material";
import {
    CreateBlogPayload
} from "@models/blog";
import { useFormik } from "formik";
import {getBlog, patchBlog, postBlogs} from "@/api/blog";
import {useNavigate, useParams} from "react-router-dom";
import {constructInit} from "@/helpers/contructInit";
import EditorJS, {OutputData} from "@editorjs/editorjs";
import Header from "@editorjs/header";
import ImageTool from "@editorjs/image";
import NestedList from "@editorjs/nested-list";
import {FileUploadTextField} from "@/components/FileUploadTextField";
import {uploadToGoogleCloud} from "@/helpers/uploadToGoogleCloud";
import {chainRules, requiredInputStringRule} from "common/input-rules";

export default function BlogDetail() {

    const navigate = useNavigate();
    const {snack, setSnack} = useContext(SnackbarContext);
    const routeParams = useParams();
    const blogId = useMemo<string | null>(() => {
        return routeParams && routeParams.id ? routeParams.id : null
    }, [routeParams]);

    const emptyBlogRequired = {
        title: { zh: "", en: "" },
        contentZh: {blocks: []},
        contentEn: {blocks: []},
        show: false,
        thumbnailUrl: ""
    }

    const emptyBlogOptional = {

    }

    const [initialValues, setInitialValues] = useState({
        ...emptyBlogRequired
    });

    const initEditorJS = (fieldName: string, data: OutputData, holder: string = "editorjs") => {
        const uploadByFile = async (file: File) => {
            try {
                const url = await uploadToGoogleCloud(file, file.name, true);
                return {
                    success: 1,
                    file: {url}
                };
            } catch (e) {
                console.error(e);
                return {success: 0};
            }
        };

        const editor = new EditorJS({
            holder,
            tools: {
                header: Header,
                image: {
                    class: ImageTool,
                    config: {
                        uploader: {
                            uploadByFile,
                            uploadByUrl: (url: string) => ({
                                success: 1,
                                file: {url}
                            })
                        }
                    }
                },
                list: {
                    class: NestedList as any,
                    inlineToolbar: true,
                    config: {
                        defaultStyle: 'unordered'
                    },
                },
                // html: RawTool
            },
            onChange: async (args) => {
                const outputData = await editor.save();
                await formik.setFieldValue(fieldName, outputData);
            },
            data: data
        });
    }

    useEffect(() => {

        const initEditors = (contentEn, contentZh) => {
            initEditorJS("contentEn", contentEn, "editorjsEn")
            initEditorJS("contentZh", contentZh, "editorjsZh")
        }

        if (blogId) {
            getBlog({id: blogId}).then(res => {
                setInitialValues(constructInit(emptyBlogRequired, emptyBlogOptional, res.data.blog))
                formik.resetForm()
                initEditors(res.data.blog.contentEn, res.data.blog.contentZh)
            })
        }
        else {
            initEditors(formik.values.contentEn, formik.values.contentZh)
        }
    }, []);

    const formik = useFormik<CreateBlogPayload>({
        enableReinitialize: true,
        initialValues: initialValues,
        onSubmit: async (values, {setFieldValue}) => {
            const body = {...values}

            try {
                if (thumbnailFile) {
                    const publicUrl = await uploadToGoogleCloud(thumbnailFile)
                    setFieldValue("thumbnailUrl", publicUrl)
                    body.thumbnailUrl = publicUrl
                }
            }
            catch (e) {
                setSnack(Snack.error('儲存失敗'))
                return
            }

            try {
                let bid = 0
                if (blogId) {
                    await patchBlog({id: blogId}, body)
                }
                else {
                    const res = await postBlogs(body)
                    bid = res.data.blog._id || 0
                }
                setSnack(Snack.success('成功儲存'))
                if (!blogId)
                    navigate(`/blogs/edit/${bid}`, {
                        replace: true
                    })
            }
            catch (e) {
                setSnack(Snack.error('儲存失敗'))
            }
        },
        validateOnBlur: false,
        validateOnChange: false,
        validate: (values: CreateBlogPayload) => {
            let errors = {
                title: {
                    zh: chainRules([requiredInputStringRule], values.title.zh),
                    en: chainRules([requiredInputStringRule], values.title.en),
                },
            }
            Object.trimLeaves(errors, [true, {}])
            return errors
        }
    });

    /**
     * Thumbnail
     * */

    const [thumbnailFile, setThumbnailFile] = useState<File | null>(null)
    const handleThumbnailFileChange = (file: File) => {
        setThumbnailFile(file)
        const url = URL.createObjectURL(file)
        formik.setFieldValue("thumbnailUrl", url)
    }

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Button type="submit"
                        variant="contained"
                        disabled={!formik.dirty || formik.isSubmitting}>
                    儲存
                </Button>
                <Paper sx={{p: 4, mt: 2}}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Switch name="show"
                                            checked={formik.values.show}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}/>
                                }
                                label="顯示文章" />
                        </Grid>
                        <Grid item xs={6}>
                            <FileUploadTextField
                                textFieldProps={{
                                    name: "thumbnailUrl",
                                    label: "縮圖URL",
                                    fullWidth: true,
                                    value: formik.values.thumbnailUrl,
                                    onChange: formik.handleChange,
                                    onBlur: formik.handleBlur,
                                    error: !!formik.errors.thumbnailUrl && formik.touched.thumbnailUrl,
                                    helperText: formik.errors.thumbnailUrl
                                }}
                                accept={"image/*"}
                                change={handleThumbnailFileChange}
                            />
                            { formik.values.thumbnailUrl &&
                                <Box sx={{mt: 2}}>
                                    <img style={{width: "100%"}} src={formik.values.thumbnailUrl} />
                                </Box>
                            }
                        </Grid>

                        <Grid item xs={6}>
                            <TextField name="title.zh"
                                       label="中文標題"
                                       size="small"
                                       fullWidth={true}
                                       value={formik.values.title.zh}
                                       onChange={formik.handleChange}
                                       onBlur={formik.handleBlur}
                                       error={!!formik.errors.title?.zh && formik.touched.title?.zh}
                                       helperText={formik.errors.title?.zh}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField name="title.en"
                                       label="英文標題"
                                       size="small"
                                       fullWidth={true}
                                       value={formik.values.title.en}
                                       onChange={formik.handleChange}
                                       onBlur={formik.handleBlur}
                                       error={!!formik.errors.title?.en && formik.touched.title?.en}
                                       helperText={formik.errors.title?.en}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <InputLabel htmlFor="editorjsZh">中文内容</InputLabel>
                            <Card variant="outlined">
                                <div id="editorjsZh"></div>
                            </Card>
                        </Grid>

                        <Grid item xs={6}>
                            <InputLabel htmlFor="editorjsEn">英文内容</InputLabel>
                            <Card variant="outlined">
                                <div id="editorjsEn"></div>
                            </Card>
                        </Grid>

                    </Grid>
                </Paper>
            </form>
        </>
    )
}
