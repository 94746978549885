import axios from "axios";
import {getFileTempUploadUrl} from "@/api/file";

export async function uploadToGoogleCloud(file: File, filename?: string, preview = false) {
    const res = await getFileTempUploadUrl({
        filename: filename || file.name,
        contentType: file.type,
        privatePreview: preview,
    });

    const {
        uploadUrl,
        publicUrl
    } = res.data;
    await axios.put(uploadUrl, file, {
        headers: {"Content-Type": file.type},
    });

    if (preview)
        await axios.post("/files/temp/make-public", {url: publicUrl}, {token: true});

    return publicUrl;
}