import {ModalBodyBox} from "@/components/ModalBodyBox";
import {Box, Button, ButtonProps, Modal, Typography} from "@mui/material";
import React, {FC, FormEvent, FormEventHandler, ReactNode, useCallback, useMemo} from "react";

export type ConfirmModalProps = {
    title: string
    open: boolean
    onClose: () => void

    confirmButtonTitle?: string
    confirmButtonProps?: ButtonProps

    cancelButtonTitle?: string

    children?: ReactNode

    onSubmit?: FormEventHandler<HTMLFormElement> | undefined
}

export const ConfirmModal: FC<ConfirmModalProps> = (props) => {

    const {
        title,
        open,
        onClose,

        confirmButtonTitle,
        confirmButtonProps,

        cancelButtonTitle,

        children,

        onSubmit
    } = props

    const handleSubmit = useCallback((event: FormEvent<HTMLFormElement>) => {
        if (onSubmit)
            onSubmit(event)
        else
            event.preventDefault()
    }, [onSubmit])

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <ModalBodyBox sx={{width: 500}}>
                <Typography variant="h6" component="h2">
                    { title }
                </Typography>
                <form noValidate
                      onSubmit={handleSubmit}>
                    <Box>
                        {!!children && children}
                    </Box>
                    <Box sx={{mt: 2}}>
                        <Button
                            type="submit"
                            variant="contained"
                            {...confirmButtonProps}>
                            { confirmButtonTitle || '確認' }
                        </Button>
                        <Button onClick={onClose}>
                            { cancelButtonTitle || '取消' }
                        </Button>
                    </Box>

                </form>
            </ModalBodyBox>
        </Modal>
    )
}