export function parseCsv(data: string, fieldSep?: string, newLine?: string) {
    const _fieldSep = fieldSep || ','
    const _newLine = newLine || '\n'
    const nSep = '\x1D'
    const qSep = '\x1E'
    const cSep = '\x1F'
    const nSepRe = new RegExp(nSep, 'g')
    const qSepRe = new RegExp(qSep, 'g')
    const cSepRe = new RegExp(cSep, 'g')
    const fieldRe = new RegExp('(?<=(^|[' + _fieldSep + '\\n]))"(|[\\s\\S]+?(?<![^"]"))"(?=($|[' + _fieldSep + '\\n]))', 'g')
    const grid: string[][] = []
    data.replace(/\r/g, '').replace(/\n+$/, '').replace(fieldRe, function (match, p1, p2) {
        return p2.replace(/\n/g, nSep).replace(/""/g, qSep).replace(/,/g, cSep)
    }).split(/\n/).forEach((line: string) => {
        const row = line.split(_fieldSep).map(cell =>
            cell.replace(nSepRe, _newLine).replace(qSepRe, '"').replace(cSepRe, ',')
        )
        grid.push(row)
    })
    return grid
}