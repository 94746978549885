import React, {useCallback, useContext, useEffect, useState} from "react";
import {Snack, SnackbarContext} from "@/context/SnackbarContext";
import {getRecommendation, postRecommendation, PostRecommendationBody} from "@/api/course";
import {FormikErrors, useFormik} from "formik";
import {constructInit} from "@/helpers/contructInit";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Grid,
    IconButton,
    MenuItem,
    TextField
} from "@mui/material";
import {RecommendationQuestion, RecommendationResult} from "@models/recommendation";
import {I18nTxt} from "@models/common";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import {CourseSelect} from "@/components/CourseSelect";

export default function Recommendation() {

    const {snack, setSnack} = useContext(SnackbarContext);

    /**
     * Init
     * */

    useEffect(() => {
        getRecommendation().then(res => {
            setInitialValues(constructInit(emptyRecommendationRequired, emptyRecommendationOptional, res.data))
            formik.resetForm()
        })
    }, [])


    const emptyRecommendationRequired = {
        questions: [],
        results: []
    }

    const emptyRecommendationOptional = {

    }

    const [initialValues, setInitialValues] = useState({
        ...emptyRecommendationRequired
    });

    const formik = useFormik<PostRecommendationBody>({
        enableReinitialize: true,
        initialValues: initialValues,
        onSubmit: async (values, {setFieldValue}) => {
            try {
                await postRecommendation(values)
                setSnack(Snack.success('成功儲存'))
            }
            catch (e) {
                setSnack(Snack.error('儲存失敗'))
            }

        },
        validateOnBlur: false,
        validateOnChange: false,
        validate: values => {
            let errors = {

            }
            Object.trimLeaves(errors, [true, {}])
            return errors
        }
    });

    const handleAddAnswer = (questionIndex: number) => {
        const questions = [
            ...formik.values.questions
        ]
        questions[questionIndex].answers = [
            ...questions[questionIndex].answers,
            {
                id: Math.max(0, ...questions[questionIndex].answers.map(a => a.id)) + 1,
                content: { zh: "", en: "" }
            }
        ]
        formik.setFieldValue("questions", questions)
    }

    const handleAddQuestion = () => {
        formik.setFieldValue("questions", [
            ...formik.values.questions,
            {
                id: Math.max(0, ...formik.values.questions.map(q => q.id)) + 1,
                question: { zh: "", en: "" },
                answers: []
            }
        ])
    }

    const answerError = useCallback((questionIdx: number, answerIdx: number) => {
        if (
            !!formik.errors.questions &&
            !!formik.errors.questions[questionIdx]
        ) {
            const questionError = formik.errors.questions[questionIdx] as FormikErrors<RecommendationQuestion>
            if (questionError.answers && questionError.answers[answerIdx]) {
                const answerError = questionError.answers[answerIdx] as FormikErrors<(RecommendationQuestion)["answers"][number]>
                return answerError
            }
        }
        return null
    }, [formik])

    const clickDeleteQuestion = (questionIndex: number) => {
        const questions = [
            ...formik.values.questions
        ]
        questions.splice(questionIndex, 1)
        formik.setFieldValue(`questions`, questions)
    }

    const clickDeleteAnswer = (questionIndex: number, answerIndex: number) => {
        const answers = [
            ...formik.values.questions[questionIndex].answers
        ]
        answers.splice(answerIndex, 1)
        formik.setFieldValue(`questions.[${questionIndex}].answers`, answers)
    }

    const handleAddResult = () => {
        formik.setFieldValue("results", [
            ...formik.values.results,
            {
                id: Math.max(0, ...formik.values.results.map(r => r.id)) + 1,
                title: "",
                courses: []
            }
        ])
    }

    const clickDeleteResult = (resultIndex: number) => {

    }

    return (
        <>
            <Card sx={{ mb: 2 }}>
                <CardContent>
                    <form noValidate
                          onSubmit={formik.handleSubmit}>
                        <CardActions>
                            <Button type="submit"
                                    variant="contained"
                                    disabled={!formik.dirty || formik.isSubmitting}>
                                儲存
                            </Button>
                        </CardActions>
                        {
                            formik.values.questions.map((q, idx) => (
                                <Card sx={{ mb: 2 }} key={q.id}>
                                    <CardContent>
                                        <Grid container spacing={2} sx={{ mb: 2 }}>
                                            <Grid item xs={6}>
                                                <TextField name={`questions.${idx}.question.zh`}
                                                           label="中文問題"
                                                           size="small"
                                                           fullWidth={true}
                                                           value={formik.values.questions[idx].question.zh}
                                                           onChange={formik.handleChange}
                                                           onBlur={formik.handleBlur}
                                                           error={!!formik.errors.questions && !!(formik.errors.questions[idx] as FormikErrors<RecommendationQuestion>).question?.zh && !!formik.touched.questions && formik.touched.questions[idx]?.question?.zh}
                                                           helperText={!!formik.errors.questions && (formik.errors.questions[idx] as FormikErrors<RecommendationQuestion>).question?.zh}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField name={`questions.${idx}.question.en`}
                                                           label="英文問題"
                                                           size="small"
                                                           fullWidth={true}
                                                           value={formik.values.questions[idx].question.en}
                                                           onChange={formik.handleChange}
                                                           onBlur={formik.handleBlur}
                                                           error={!!formik.errors.questions && !!(formik.errors.questions[idx] as FormikErrors<RecommendationQuestion>).question?.en && !!formik.touched.questions && formik.touched.questions[idx]?.question?.en}
                                                           helperText={!!formik.errors.questions && (formik.errors.questions[idx] as FormikErrors<RecommendationQuestion>).question?.en}
                                                />
                                            </Grid>
                                            {
                                                !!q.answers.length && (
                                                    <Grid item xs={12}>
                                                        {
                                                            q.answers.map((a, ansIdx) => (
                                                                <Box
                                                                    sx={{
                                                                        mb: 2,
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                    }}
                                                                    key={a.id}>
                                                                    <Box>
                                                                        { `${ansIdx + 1}.` }
                                                                    </Box>
                                                                    <TextField sx={{ ml: 2 }}
                                                                               name={`questions.${idx}.answers.${ansIdx}.content.zh`}
                                                                               label="中文答案"
                                                                               size="small"
                                                                               fullWidth={true}
                                                                               value={a.content.zh}
                                                                               onChange={formik.handleChange}
                                                                               onBlur={formik.handleBlur}
                                                                               error={!!answerError(idx, ansIdx)?.content?.zh}
                                                                               helperText={answerError(idx, ansIdx)?.content?.zh}
                                                                    />
                                                                    <TextField sx={{ ml: 2 }}
                                                                               name={`questions.${idx}.answers.${ansIdx}.content.en`}
                                                                               label="英文答案"
                                                                               size="small"
                                                                               fullWidth={true}
                                                                               value={a.content.en}
                                                                               onChange={formik.handleChange}
                                                                               onBlur={formik.handleBlur}
                                                                               error={!!answerError(idx, ansIdx)?.content?.en}
                                                                               helperText={answerError(idx, ansIdx)?.content?.en}
                                                                    />

                                                                    {
                                                                        (idx !== (formik.values.questions.length - 1)) && (
                                                                            <TextField
                                                                                sx={{ ml: 2 }}
                                                                                name={`questions.${idx}.answers.${ansIdx}.nextQuestion`}
                                                                                label="下條問題"
                                                                                size="small"
                                                                                fullWidth={true}
                                                                                value={a.nextQuestion}
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                                error={!!answerError(idx, ansIdx)?.nextQuestion}
                                                                                helperText={answerError(idx, ansIdx)?.nextQuestion}
                                                                                select>
                                                                                {
                                                                                    formik.values.questions.slice(idx + 1).map(nQ => (
                                                                                        <MenuItem key={nQ.id} value={nQ.id}>
                                                                                            { `[${nQ.id}] - ${nQ.question.zh}` }
                                                                                        </MenuItem>
                                                                                    ))
                                                                                }
                                                                            </TextField>
                                                                        )
                                                                    }

                                                                    <TextField
                                                                        sx={{ ml: 2 }}
                                                                        name={`questions.${idx}.answers.${ansIdx}.resultSet`}
                                                                        label="結果"
                                                                        size="small"
                                                                        fullWidth={true}
                                                                        value={a.resultSet}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                        error={!!answerError(idx, ansIdx)?.resultSet}
                                                                        helperText={answerError(idx, ansIdx)?.resultSet}
                                                                        select>
                                                                        {
                                                                            formik.values.results.map(result => (
                                                                                <MenuItem key={result.id} value={result.id}>
                                                                                    { result.title }
                                                                                </MenuItem>
                                                                            ))
                                                                        }
                                                                    </TextField>

                                                                    <IconButton
                                                                        sx={{ ml: 2 }}
                                                                        size='small'
                                                                        onClick={() => clickDeleteAnswer(idx, ansIdx)}
                                                                    >
                                                                        <ClearIcon></ClearIcon>
                                                                    </IconButton>
                                                                </Box>
                                                            ))
                                                        }
                                                    </Grid>
                                                )
                                            }
                                        </Grid>
                                        <Divider sx={{ mb: 2 }} />
                                        <IconButton
                                            sx={{ mr: 1 }}
                                            color='error'
                                            size='small'
                                            onClick={() => clickDeleteQuestion(idx)}
                                        >
                                            <DeleteIcon></DeleteIcon>
                                        </IconButton>
                                        <IconButton
                                            sx={{ mr: 1 }}
                                            size='small'
                                            onClick={() => handleAddAnswer(idx)}
                                        >
                                            <AddIcon></AddIcon>
                                        </IconButton>
                                    </CardContent>
                                </Card>
                            ))
                        }
                        <Button variant="contained"
                                fullWidth={true}
                                onClick={() => handleAddQuestion()}>
                            新增問題
                        </Button>
                    </form>
                </CardContent>
            </Card>
            <Card>
                <CardContent>
                    {
                        formik.values.results.map((result, idx) => (
                            <Box
                                sx={{
                                    mb: 2,
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                                key={result.id}>
                                <Box>
                                    { `${idx + 1}.` }
                                </Box>
                                <TextField sx={{ ml: 2 }}
                                           name={`results.${idx}.title`}
                                           label="結果組別"
                                           size="small"
                                           fullWidth={true}
                                           value={result.title}
                                           onChange={formik.handleChange}
                                           onBlur={formik.handleBlur}
                                           error={!!formik.errors.results && !!(formik.errors.results[idx] as FormikErrors<RecommendationResult>).title}
                                           helperText={!!formik.errors.results && (formik.errors.results[idx] as FormikErrors<RecommendationResult>).title}
                                />
                                <CourseSelect
                                    sx={{ ml: 2 }}
                                    name={`results.${idx}.courses`}
                                    label="課程"
                                    size="small"
                                    fullWidth={true}
                                    SelectProps={{
                                        multiple: true
                                    }}
                                    value={result.courses}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={!!formik.errors.results && !!(formik.errors.results[idx] as FormikErrors<RecommendationResult>).courses}
                                    helperText={!!formik.errors.results && (formik.errors.results[idx] as FormikErrors<RecommendationResult>).courses}
                                ></CourseSelect>

                                <IconButton
                                    sx={{ ml: 2 }}
                                    size='small'
                                    onClick={() => clickDeleteResult(idx)}
                                >
                                    <ClearIcon></ClearIcon>
                                </IconButton>
                            </Box>
                        ))
                    }
                    <Button variant="contained"
                            fullWidth={true}
                            onClick={() => handleAddResult()}>
                        新增結果
                    </Button>
                </CardContent>
            </Card>
        </>
    )

}
