import {DbDate} from "@models/common";
import {OrderStatus, PaymentMethod, ReceiptRecord} from "@models/order";
import {Product} from "@models/product";

export enum MailingStatus {
    Pending,
    Mailed
}

export const mailingStatusList = [
    MailingStatus.Pending,
    MailingStatus.Mailed,
] as const;

export const mailingStatusUI = {
    [MailingStatus.Pending]: {
        title: "待寄出"
    },
    [MailingStatus.Mailed]: {
        title: "已寄出"
    }
} as const;

export type MailingMethod = "pick-up" | "mail"
export const mailingMethodList = ["pick-up", "mail"] as const;

export const mailingMethodUI = {
    ["pick-up"]: {
        title: "自取"
    },
    ["mail"]: {
        title: "郵寄"
    }
} as const;

export interface ProductOrder {
    _id: string // PORD-00000000

    studentId: string

    productItems: ProductOrderItem[]

    price: number

    // seal from student
    name: string
    email: string
    phone: string

    mailingMethod: MailingMethod
    shippingAddress?: string // multi line string
    mailedInfo?: string // SF link / id

    status: OrderStatus
    mailingStatus: MailingStatus
    paymentMethod?: PaymentMethod

    stripeTransactionId?: string
    receiptRecords?: ReceiptRecord[]

    createdBy: string
    createdAt: DbDate
    paidAt?: DbDate
    refundedAt?: DbDate
}

export interface ProductOrderItem {
    product: Pick<Product, "discounted_price" | "price" | "title" | "_id">
    quantity: number
    price: number
}

export interface CreateProductOrderItem {
    productId: number
    quantity: number
}

export type CreateProductOrderPayload =
    Omit<ProductOrder, "_id" | "status" | "created" | "name" | "email" | "phone">
    & { productList: CreateProductOrderItem[] };
export type UpdateProductOrderPayload = Partial<Pick<ProductOrder, "price" | "paymentMethod" | "status" | "paidAt" | "refundedAt">>;

export type AdminCreateProductOrderPayload = Pick<ProductOrder,
    "studentId" | "price" | "status" | "mailingStatus" | "mailingMethod" | // required
    "shippingAddress" | "mailedInfo" | "paymentMethod" | "paidAt" | "refundedAt"> // optional
    & { productList: CreateProductOrderItem[] }

export type AdminCreateMultiProductOrderPayload = { productOrders: AdminCreateProductOrderPayload[] }

export interface ProductOrderFilter {
    studentId?: string
    status?: OrderStatus
    mailingStatus?: MailingStatus
}

export interface ProductCart {
    _id: string
    productList: CreateProductOrderItem[]
}

export interface ProductCheckoutPayload {
    productList: CreateProductOrderItem[],
    mailingMethod: MailingMethod,
    shippingAddress?: string, // multi line string
    paymentMethod: PaymentMethod
}