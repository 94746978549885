import { PageSizeOptions } from '@/data/options'
import { QUERY_KEY } from '@/data/query-key'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import React, {useCallback, useContext, useMemo, useRef, useState} from 'react'
import {useQuery} from 'react-query'
import {Box, Button, IconButton, InputAdornment, Paper, TextField, Typography} from "@mui/material";
import {useFormik} from "formik";
import {
  chainRules,
  requiredInputNumberRule,
  requiredInputStringRule
} from "common/input-rules";
import {Snack, SnackbarContext} from "@/context/SnackbarContext";
import {ConfirmModal} from "@/components/ConfirmModal";
import dayjs from "dayjs";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {AccountRecordType, CreateSalesPayload, SalesRecord} from "@models/account";
import {
  deleteAccountRecord,
  getAccountRecords, GetAccountRecordsParams,
  patchAccountRecords,
  postAccountRecords,
  postAccountRecordsBatch
} from "@/api/account";
import {constructInit} from "@/helpers/contructInit";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import {DateTimeField} from "@mui/x-date-pickers/DateTimeField";
import {Confirm, ConfirmContext} from "@/context/ConfirmContext";
import {CsvExportButton} from "@/components/CsvExportButton";
import {GridRowSelectionModel} from "@mui/x-data-grid/models/gridRowSelectionModel";
import {parseCsv} from "@/helpers/parseCsv";
import {CsvImportButton} from "@/components/CsvImportButton";
import {Pagination} from "common/models/pagination";
import {useAdminStore} from "@/hooks/useAdminStore";
import {useAppDispatch} from "@/store";
import {getAdminNamesLookup} from "@/store/slices/admin";

export default function SalesList() {

  const {snack, setSnack} = useContext(SnackbarContext);
  const {confirmation, setConfirmation} = useContext(ConfirmContext);
  const {
    adminNamesLookup
  } = useAdminStore()
  const dispatch = useAppDispatch()

  const columns: GridColDef<SalesRecord>[] = [
    {
      field: '_id',
      headerName: '收入ID',
      sortable: false,
      flex: 1,
      minWidth: 200
    },
    {
      field: 'title',
      headerName: '標題',
      sortable: false,
      flex: 1,
      minWidth: 200
    },
    {
      field: 'amount',
      headerName: '數目',
      sortable: false,
      flex: 1,
      minWidth: 150
    },
    {
      field: 'creator',
      headerName: '創建人',
      sortable: false,
      flex: 1,
      minWidth: 200,
      valueGetter: ({ row }) => `${row.creator} ${adminNamesLookup[row.creator]}`
    },
    {
      field: 'salesDate',
      headerName: '收入日期',
      sortable: false,
      flex: 1,
      minWidth: 120,
      valueGetter: ({ row }) => dayjs(row.recordDate).format('DD/MM/YYYY')
    },
    {
      field: 'createdAt',
      headerName: '創建日期',
      sortable: false,
      flex: 1,
      minWidth: 120,
      valueGetter: ({ row }) => dayjs(row.created).format('DD/MM/YYYY')
    },
    {
      field: 'action',
      headerName: '行動',
      sortable: false,
      minWidth: 120,
      renderCell: ({ row }) => {
        return (
          <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
            <IconButton
              color='primary' size='small'
              onClick={() => clickEdit(row)}
              sx={{ ml: 0.5 }}
            >
              <EditIcon></EditIcon>
            </IconButton>
            <IconButton
              color='error'
              size='small'
              onClick={() => clickDelete(row)}
              sx={{ ml: 0.5 }}
            >
              <DeleteIcon></DeleteIcon>
            </IconButton>
          </Box>
        )
      }
    }
  ]


  const [paginationModel, setPaginationModel] = useState<{page: number, pageSize: number}>({
    page: 0,
    pageSize: 50
  })
  const [keyword, setKeyword] = useState<string | null>(null)

  const [query, setQuery] = useState<GetAccountRecordsParams & Partial<Pagination>>({
    type: "sales",
    skip: paginationModel.pageSize * paginationModel.page,
    limit: paginationModel.pageSize,
    count: true
  })

  const { data, refetch, isLoading } = useQuery(
    [QUERY_KEY.ACCOUNT_LIST, query],
    async () => {
      return (await getAccountRecords(query)).data as {
        accountRecords: SalesRecord[],
        count: number
      }
    }
  )

  const salesList = useMemo(() => {
    if (!data)
      return []
    dispatch(getAdminNamesLookup(data.accountRecords.map(u => u.creator)))
    return data.accountRecords.map(u => ({
      id: u._id?.toString() || "", // Add id for MUI
      ...u
    }))
  }, [data])

  const clickAdd = () => handleOpenModal()

  /***
   * Row actions
   */

  const clickEdit = (row: SalesRecord) => handleOpenModal(JSON.parse(JSON.stringify(row)))
  const clickDelete = (row: SalesRecord) => {
    setConfirmation(Confirm.delete({
      confirmButtonProps: {
        onClick: async () => {
          if (!row._id)
            return
          try {
            await deleteAccountRecord({id: row._id.toString()})
            setSnack(Snack.success('成功刪除'))
            await refetch()
            setConfirmation(Confirm.close())
          }
          catch (e) {
            setSnack(Snack.error('刪除失敗'))
          }
        }
      }
    }))
  }

  /***
   * Details Modal
   */

  const emptySalesRequired: CreateSalesPayload = {
    title: "",
    amount: 0,
    recordDate: "",
    type: "sales"
  }
  const emptySalesOptional = {

  }

  const [initialValues, setInitialValues] = useState<CreateSalesPayload>({
    ...emptySalesRequired
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (values) => {
      try {
        if (editingSales) {
          await patchAccountRecords({id: editingSales._id as string}, values)
        }
        else {
          await postAccountRecords(values)
        }
        setSnack(Snack.success('成功儲存'))
        handleCloseModal()
        await refetch()
      }
      catch (e) {
        setSnack(Snack.error('儲存失敗'))
      }
    },
    validateOnBlur: false,
    validateOnChange: false,
    validate: (values: CreateSalesPayload) => {
      let errors = {
        title: chainRules([requiredInputStringRule], values.title),
        amount: chainRules([requiredInputNumberRule], values.amount)
      }
      Object.trimLeaves(errors, [true, {}]);
      return errors;
    }
  })
  const [editingSales, setEditingSales] = useState<SalesRecord | null>(null)
  const [openModal, setOpenModal] = useState(false)
  const handleOpenModal = useCallback((sales?: SalesRecord) => {
    setEditingSales(sales ? sales : null)
    setInitialValues(constructInit(emptySalesRequired, emptySalesOptional, sales))
    formik.resetForm()
    setOpenModal(true)
  }, [formik])
  const handleCloseModal = () => setOpenModal(false)

  /***
   * Row actions
   */

  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);

  const handleRowSelectionChange = (rowSelectionModel: GridRowSelectionModel) => {
    setSelectedRows(rowSelectionModel);
  };

  /**
   * CSV Import
   * */

  const importTransformer = (row: string[]) => {
    return {
      title: row[0],
      amount: Number(row[1]),
      recordDate: row[2],
      type: "sales" as "sales"
    }
  }

  const importResultChanged = async (result: CreateSalesPayload[]) => {
    try {
      await postAccountRecordsBatch({
        accountRecords: result
      })
      setSnack(Snack.success('成功匯入'))
    }
    catch (e) {
      setSnack(Snack.error('匯入失敗'))
    }
    await refetch()
  }

  return (
    <>
      <Button
        variant="contained"
        onClick={clickAdd}
      >
        新增
      </Button>

      <CsvExportButton sx={{ml: 2}}
                       disabled={selectedRows.length === 0}
                       fileName={"saless"}
                       header={[]}
                       rows={salesList.filter(o => selectedRows.includes(o.id)).map(e => ([
                         e.id,
                         e.title,
                         e.amount,
                         e.creator,
                         e.type,
                         dayjs(e.created).format('DD/MM/YYYY'),
                         dayjs(e.recordDate).format('DD/MM/YYYY')
                       ]))}/>
      <CsvImportButton<CreateSalesPayload> sx={{ml: 2}}
                                             transformer={importTransformer}
                                             resultChanged={importResultChanged}/>

      <Paper elevation={0} sx={{ mt: 2, p: 2 }}>
        <Typography
          variant="h6"
          component="div"
          sx={{ mb: 2 }}
        >
          篩選
        </Typography>
        <Box sx={{display: "flex", alignItems: "center", mb: 2}}>
          <TextField sx={{ mr: 1 }}
                     fullWidth={true}
                     label="搜索標題"
                     variant="outlined"
                     size="small"
                     value={query.keyword || ""}
                     onChange={(e) => setQuery({...query, keyword: e.target.value as string})}
          />
          <TextField fullWidth={true}
                     type="number"
                     label="數目下限"
                     variant="outlined"
                     size="small"
                     value={query.minAmount || ""}
                     onChange={(e) => setQuery({...query, minAmount: e.target.value === "" ? undefined : Number(e.target.value)})}
          />
          <Typography component='div' sx={{ mx: 1 }}>-</Typography>
          <TextField sx={{ mr: 1 }}
                     fullWidth={true}
                     type="number"
                     label="數目上限"
                     variant="outlined"
                     size="small"
                     value={query.maxAmount || ""}
                     onChange={(e) => setQuery({...query, maxAmount: e.target.value === "" ? undefined : Number(e.target.value)})}
          />
        </Box>
        <Box sx={{display: "flex", alignItems: "center"}}>
          <DateTimePicker label="開始時間"
                          slotProps={{
                            textField: {size: "small"},
                            field: { clearable: true }
                          }}
                          value={query.since ? dayjs(query.since) : null}
                          format={"DD/MM/YYYY HH:mm"}
                          onChange={(newValue) => setQuery({...query, since: newValue?.valueOf() || 0})}/>
          <Typography component='div' sx={{ mx: 1 }}>-</Typography>
          <DateTimePicker label="完結時間"
                          slotProps={{
                            textField: {size: "small"},
                            field: { clearable: true }
                          }}
                          value={query.until ? dayjs(query.until) : null}
                          format={"DD/MM/YYYY HH:mm"}
                          onChange={(newValue) => setQuery({...query, until: newValue?.valueOf() || 0})}/>
        </Box>
      </Paper>

      <DataGrid
        sx={{
          mt: 2,
          backgroundColor: 'white'
        }}
        loading={isLoading}
        rows={salesList}
        columns={columns}

        pagination
        paginationMode='server'
        paginationModel={paginationModel}
        rowCount={data?.count || 0}
        pageSizeOptions={PageSizeOptions}
        onPaginationModelChange={setPaginationModel}

        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableRowSelectionOnClick

        checkboxSelection
        onRowSelectionModelChange={handleRowSelectionChange}
      />

      {/* Details Modal */}
      <ConfirmModal title={`${editingSales ? '修改' : '新增'}收入記錄`}
                    open={openModal}
                    onClose={handleCloseModal}
                    confirmButtonTitle={'儲存'}
                    confirmButtonProps={{
                      disabled: !formik.dirty || formik.isSubmitting
                    }}
                    onSubmit={formik.handleSubmit}>
        <TextField sx={{mt: 2}}
                   id="title"
                   label="標題"
                   variant="outlined"
                   size="small"
                   fullWidth={true}
                   value={formik.values.title}
                   onChange={formik.handleChange}
                   onBlur={formik.handleBlur}
                   error={!!formik.errors.title && formik.touched.title}
                   helperText={formik.errors.title}
        />
        <TextField sx={{mt: 2}}
                   type="number"
                   id="amount"
                   label="數目"
                   variant="outlined"
                   size="small"
                   fullWidth={true}
                   value={formik.values.amount}
                   onChange={formik.handleChange}
                   onBlur={formik.handleBlur}
                   error={!!formik.errors.amount && formik.touched.amount}
                   helperText={formik.errors.amount}
                   InputProps={{
                     startAdornment: <InputAdornment position="start">$</InputAdornment>,
                   }}
        />
        <DateTimeField sx={{mt: 2}}
                       label="日期"
                       size="small"
                       value={dayjs(formik.values.recordDate)}
                       format={"DD/MM/YYYY"}
                       onChange={(newValue) => {
                         formik.setFieldValue(`recordDate`, newValue !== null ? newValue.format('YYYY-MM-DD') : '')
                       }}/>
      </ConfirmModal>
    </>
  )
}
