import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Snack, SnackbarContext} from "@/context/SnackbarContext";
import {Box, IconButton} from "@mui/material";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {PageSizeOptions} from "@/data/options";
import dayjs from "dayjs";
import {useQuery} from "react-query";
import {QUERY_KEY} from "@/data/query-key";
import {GridRowSelectionModel} from "@mui/x-data-grid/models/gridRowSelectionModel";
import {
    getTutorLeaveRequests,
    patchTutorLeaveRequestStatus, postTutorLeaveRequestNotification,
    postTutorLeaveRequestSubstituteTutor,
    PostTutorLeaveRequestSubstituteTutorData
} from "@/api/tutor";
import {TutorLeaveRequest, TutorLeaveRequestStatus, tutorLeaveRequestStatusUI} from "@models/tutor";
import {useTutorStore} from "@/hooks/useTutorStore";
import {useAppDispatch} from "@/store";
import {getTutorLookup} from "@/store/slices/tutor";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import {Confirm, ConfirmContext} from "@/context/ConfirmContext";
import {TutorSelect} from "@/components/TutorSelect";
import { ConfirmModal } from "@/components/ConfirmModal";
import {useFormik} from "formik";
import {chainRules, requiredInputStringRule} from "common/input-rules";
import SendIcon from "@mui/icons-material/Send";

export default function TutorLeaveRequestList() {

    const {snack, setSnack} = useContext(SnackbarContext);
    const {confirmation, setConfirmation} = useContext(ConfirmContext);

    const columns: GridColDef<TutorLeaveRequest>[] = [
        {
            field: '_id',
            headerName: '請假申請ID',
            sortable: false,
            flex: 1,
            minWidth: 200
        },
        {
            field: 'tutorId',
            headerName: '導師名稱',
            sortable: false,
            flex: 1,
            minWidth: 200,
            valueGetter: ({ row }) => (tutorLookup[row.tutorId] ? tutorLookup[row.tutorId].name : "-")
        },
        {
            field: 'status',
            headerName: '狀態',
            sortable: false,
            flex: 1,
            minWidth: 200,
            valueGetter: ({ row }) => tutorLeaveRequestStatusUI[row.status].title
        },
        {
            field: 'notiSent',
            headerName: '已通知',
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({row}) => {
                return (
                    row.notiSent ? <DoneIcon/> : <></>
                );
            }
        },
        {
            field: 'createdAt',
            headerName: '創建日期',
            sortable: false,
            flex: 1,
            minWidth: 200,
            valueGetter: ({ row }) => dayjs(row.createdAt).format('DD/MM/YYYY')
        },
        {
            field: 'action',
            headerName: '行動',
            sortable: false,
            minWidth: 120,
            renderCell: ({row}) => {
                return (
                    <Box sx={{display: 'flex', justifyContent: 'end', width: '100%'}}>
                        {
                            row.status === TutorLeaveRequestStatus.Pending && (
                                <>
                                    <IconButton
                                        color="secondary"
                                        size="small"
                                        onClick={() => clickAccept(row)}
                                        sx={{ml: 0.5}}
                                    >
                                        <DoneIcon></DoneIcon>
                                    </IconButton>
                                    <IconButton
                                        color="error"
                                        size="small"
                                        onClick={() => clickDecline(row)}
                                        sx={{ml: 0.5}}
                                    >
                                        <ClearIcon></ClearIcon>
                                    </IconButton>
                                </>
                            )
                        }

                        {
                            row.status === TutorLeaveRequestStatus.Accepted && (
                                <>
                                    <IconButton
                                        color="error"
                                        size="small"
                                        onClick={() => clickSetSubstituteTutor(row)}
                                        sx={{ml: 0.5}}
                                    >
                                        <ClearIcon></ClearIcon>
                                    </IconButton>
                                    <IconButton
                                        color="primary" size="small"
                                        onClick={() => clickSendNotification(row)}
                                        sx={{ml: 0.5}}
                                    >
                                        <SendIcon></SendIcon>
                                    </IconButton>
                                </>
                            )
                        }
                    </Box>
                );
            }
        }
    ];

    const [paginationModel, setPaginationModel] = useState<{ page: number, pageSize: number }>({
        page: 0,
        pageSize: 50
    });

    const query = {
        skip: paginationModel.pageSize * paginationModel.page,
        limit: paginationModel.pageSize,
        count: true,
    };

    const {data, refetch, isLoading} = useQuery(
        [QUERY_KEY.TUTOR_LEAVE_REQUEST_LIST, query],
        async () => {
            return (await getTutorLeaveRequests(query)).data;
        }
    );

    const tutorLeaveRequestList = useMemo<TutorLeaveRequest[]>(() => {
        if (!data)
            return [];
        return data.tutors.map(r => ({
            id: r._id, // Add id for MUI
            ...r
        }));
    }, [data]);

    /**
     * Init
     * */

    const dispatch = useAppDispatch();

    const {
        tutorLookup
    } = useTutorStore();

    useEffect(() => {
        dispatch(getTutorLookup());
    }, []);

    /***
     * Row actions
     */

    const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);

    const handleRowSelectionChange = (rowSelectionModel: GridRowSelectionModel) => {
        setSelectedRows(rowSelectionModel);
    };

    const clickAccept = (row: TutorLeaveRequest) => {
        setConfirmation(new Confirm({
            title: "你確認要批准申請嗎？",
            open: true,
            confirmButtonProps: {
                onClick: async () => {
                    if (!row._id)
                        return;
                    try {
                        await patchTutorLeaveRequestStatus({id: row._id}, {status: TutorLeaveRequestStatus.Accepted});
                        setSnack(Snack.success('成功批准'));
                        await refetch();
                        setConfirmation(Confirm.close());
                    } catch (e) {
                        setSnack(Snack.error('批准失敗'));
                    }
                }
            }
        }));
    };

    const clickDecline = (row: TutorLeaveRequest) => {
        setConfirmation(new Confirm({
            title: "你確認要拒絕申請嗎？",
            open: true,
            confirmButtonProps: {
                onClick: async () => {
                    if (!row._id)
                        return;
                    try {
                        await patchTutorLeaveRequestStatus({id: row._id}, {status: TutorLeaveRequestStatus.Rejected});
                        setSnack(Snack.success('成功拒絕'));
                        await refetch();
                        setConfirmation(Confirm.close());
                    } catch (e) {
                        setSnack(Snack.error('拒絕失敗'));
                    }
                }
            }
        }));
    };

    const clickSendNotification = (row: TutorLeaveRequest) => {
        setConfirmation(new Confirm({
            title: "你確認要發送通知嗎？",
            open: true,
            confirmButtonProps: {
                onClick: async () => {
                    if (!row._id)
                        return;
                    try {
                        await postTutorLeaveRequestNotification({id: row._id});
                        setSnack(Snack.success('成功發送'));
                        await refetch();
                        setConfirmation(Confirm.close());
                    } catch (e) {
                        setSnack(Snack.error('發送失敗'));
                    }
                }
            }
        }));
    };

    const clickSetSubstituteTutor = (row: TutorLeaveRequest) => {
        handleOpenModal(row);
    };

    const substituteTutorFormik = useFormik<PostTutorLeaveRequestSubstituteTutorData>({
        enableReinitialize: true,
        initialValues: {
            subTutorId: ""
        },
        onSubmit: async (values) => {
            try {
                if (!editingTutorLeaveRequest)
                    return;
                await postTutorLeaveRequestSubstituteTutor({id: editingTutorLeaveRequest._id}, values);
                setSnack(Snack.success('成功儲存'));
                handleCloseSubstituteTutorModal();
                await refetch();
            } catch (e) {
                setSnack(Snack.error('儲存失敗'));
            }
        },
        validateOnBlur: false,
        validateOnChange: false,
        validate: (values) => {
            let errors = {
                subTutorId: chainRules([requiredInputStringRule], values.subTutorId)
            };
            Object.trimLeaves(errors, [true, {}]);
            return errors;
        }
    });
    const [editingTutorLeaveRequest, setEditingTutorLeaveRequest] = useState<TutorLeaveRequest | null>(null);
    const [openSubstituteTutorModal, setOpenSubstituteTutorModal] = useState(false);
    const handleOpenModal = useCallback((tutorLeaveRequest?: TutorLeaveRequest) => {
        setEditingTutorLeaveRequest(tutorLeaveRequest ? tutorLeaveRequest : null);
        substituteTutorFormik.resetForm();
        setOpenSubstituteTutorModal(true);
    }, [substituteTutorFormik]);
    const handleCloseSubstituteTutorModal = () => setOpenSubstituteTutorModal(false);

    return (
        <>
            {/*<Button variant="contained" component={RouterLink} to="/product-orders/add">*/}
            {/*    新增*/}
            {/*</Button>*/}

            <DataGrid
                sx={{
                    mt: 2,
                    backgroundColor: 'white'
                }}
                loading={isLoading}
                rows={tutorLeaveRequestList}
                columns={columns}

                pagination
                paginationMode='server'
                paginationModel={paginationModel}
                rowCount={data?.count || 0}
                pageSizeOptions={PageSizeOptions}
                onPaginationModelChange={setPaginationModel}

                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableRowSelectionOnClick

                // checkboxSelection
                // onRowSelectionModelChange={handleRowSelectionChange}
            />

            {/* Substitute Tutor Modal */}
            <ConfirmModal title={`設定代課導師`}
                          open={openSubstituteTutorModal}
                          onClose={handleCloseSubstituteTutorModal}
                          confirmButtonTitle={'儲存'}
                          confirmButtonProps={{
                              disabled: !substituteTutorFormik.dirty || substituteTutorFormik.isSubmitting
                          }}
                          onSubmit={substituteTutorFormik.handleSubmit}>
                <TutorSelect
                  name="subTutorId"
                  label="代課導師"
                  size="small"
                  fullWidth={true}
                  value={substituteTutorFormik.values.subTutorId}
                  onChange={substituteTutorFormik.handleChange}
                  onBlur={substituteTutorFormik.handleBlur}
                  error={!!substituteTutorFormik.errors.subTutorId && substituteTutorFormik.touched.subTutorId}
                  helperText={substituteTutorFormik.errors.subTutorId}/>
            </ConfirmModal>
        </>
    )
}
