import {Level} from "@models/common";
import {CourseType} from "@models/course";

export const courseLevels = [
    Level.Preschool,
    Level.P1,
    Level.P2,
    Level.P3,
    Level.P4,
    Level.P5,
    Level.P6,
    Level.F1,
    Level.F2,
    Level.F3,
    Level.F4,
    Level.F5,
    Level.F6,
    Level.Adults
]

export const courseLevelOptions = [
    {
        title: "幼兒班",
        value: Level.Preschool
    },
    {
        title: "P1",
        value: Level.P1
    },
    {
        title: "P2",
        value: Level.P2
    },
    {
        title: "P3",
        value: Level.P3
    },
    {
        title: "P4",
        value: Level.P4
    },
    {
        title: "P5",
        value: Level.P5
    },
    {
        title: "P6",
        value: Level.P6
    },
    {
        title: "F1",
        value: Level.F1
    },
    {
        title: "F2",
        value: Level.F2
    },
    {
        title: "F3",
        value: Level.F3
    },
    {
        title: "F4",
        value: Level.F4
    },
    {
        title: "F5",
        value: Level.F5
    },
    {
        title: "F6",
        value: Level.F6
    },
    {
        title: "成人班",
        value: Level.Adults
    },
]

export const courseTypeOptions = [
    {
        title: "常規課程",
        value: CourseType.Regular
    },
    {
        title: "單次課程",
        value: CourseType.OneOff
    },
]
