import {Box, Button, Fab, IconButton} from "@mui/material";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {Snack, SnackbarContext} from "@/context/SnackbarContext";
import DeleteIcon from "@mui/icons-material/Delete";
import {getHolidays, setHolidays} from "@/api/holiday";
import {ConfirmModal} from "@/components/ConfirmModal";
import dayjs from "dayjs";
import {DateTimeField} from "@mui/x-date-pickers/DateTimeField";
import {useFormik} from "formik";
import {chainRules, requiredInputStringRule} from "common/input-rules";

type GridHoliday = {
    id: string,
    date: string
}

export default function HolidayList() {

    const { snack, setSnack } = useContext(SnackbarContext);

    const [holidayList, setHolidayList] = useState<GridHoliday[]>([])

    const columns: GridColDef<GridHoliday>[] = [
        {
            field: "id",
            headerName: "日期",
            sortable: false,
            flex: 1,
            minWidth: 200,
        },
        {
            field: "action",
            headerName: "行動",
            sortable: false,
            minWidth: 120,
            renderCell: ({ row }) => {
                return (
                    <Box sx={{ display: "flex", justifyContent: "end", width: "100%" }}>
                        <IconButton
                            color='error'
                            size='small'
                            onClick={() => clickDelete(row)}
                            sx={{ ml: 0.5 }}
                        >
                            <DeleteIcon></DeleteIcon>
                        </IconButton>
                    </Box>
                );
            },
        },
    ];

    /**
     * Init
     * */

    useEffect(() => {
        getHolidays().then(res => {
            setHolidayList(res.data.holidays.map(h => ({
                id: h,
                date: h
            })))
        })
    }, [])

    /***
     * Row actions
     */

    const clickDelete = (row: GridHoliday) => {
        setHolidayList(holidayList.filter(h => h.id !== row.id))
    }

    const clickSave = async () => {
        try {
            await setHolidays({
                holidays: holidayList.map(h => h.date)
            });
            setSnack(Snack.success('成功儲存'));
            // await refetch()
        }
        catch (e) {
            setSnack(Snack.error('儲存失敗'));
        }
    }

    /***
     * Add Holiday Modal
     */

    const formik = useFormik<GridHoliday>({
        initialValues: {
            id: "",
            date: ""
        },
        onSubmit: async (values) => {
            setHolidayList([
                ...holidayList,
                values
            ])
            handleCloseModal()
        },
        validateOnBlur: false,
        validateOnChange: false,
        validate: (values: GridHoliday) => {
            let errors = {
                id: chainRules([requiredInputStringRule], values.id),
                date: chainRules([requiredInputStringRule], values.date),
            }
            Object.trimLeaves(errors, [true, {}]);
            return errors;
        }
    })
    const [openModal, setOpenModal] = useState(false)
    const handleOpenModal = useCallback(() => {
        formik.resetForm()
        setOpenModal(true)
    }, [formik])
    const handleCloseModal = () => setOpenModal(false)

    return (
        <>
            <Button variant="contained" onClick={clickSave}>
                儲存
            </Button>

            <DataGrid
                sx={{
                    mt: 2,
                    backgroundColor: "white",
                }}
                // loading={isLoading}
                rows={holidayList}
                columns={columns}
                getRowId={(row) => (row.id || "").toString()}

                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableRowSelectionOnClick

                // checkboxSelection
                // onRowSelectionModelChange={handleRowSelectionChange}
            />

            <Box>
                <Button sx={{ mt: 2 }}
                        variant="contained"
                        fullWidth={true}
                        onClick={handleOpenModal}>
                    新增假期
                </Button>
            </Box>

            {/* Modal */}
            <ConfirmModal title={`新增假期`}
                          open={openModal}
                          onClose={handleCloseModal}
                          confirmButtonProps={{
                              disabled: !formik.dirty || formik.isSubmitting
                          }}
                          onSubmit={formik.handleSubmit}>
                <DateTimeField sx={{mt: 2}}
                               label="日期"
                               size="small"
                               value={dayjs(formik.values.date)}
                               format={"DD/MM/YYYY"}
                               onChange={(newValue) => {
                                   formik.setFieldValue(`date`, newValue !== null ? newValue.format('YYYY-MM-DD') : '')
                                   formik.setFieldValue(`id`, newValue !== null ? newValue.format('YYYY-MM-DD') : '')
                               }}/>
            </ConfirmModal>
        </>
    );
}
