import { CourseType, WebOrderCourseRes } from "@models/course";
import { DbDate } from "@models/common";

export interface Order {
    _id: string // CORD-00000000

    studentId: string

    // seal from student
    name: string
    email: string
    phone: string

    courseId: number
    courseType: CourseType

    // regular
    classId?: number

    timeslots: OneOffOrderTimeslot[] | RegularOrderTimeslot[]

    price: number

    status: OrderStatus
    paymentMethod?: PaymentMethod
    referralCredit?: boolean
    leaveCredit?: number

    stripeTransactionId?: string
    receiptRecords?: ReceiptRecord[]

    createdBy: string
    createdAt: DbDate
    paidAt?: DbDate
    refundedAt?: DbDate
}

export type RegularOrder = Order & { classId: number, timeslots: RegularOrderTimeslot[] }
export type OneOffOrder = Order & { classId: undefined, timeslots: OneOffOrderTimeslot[] }

export interface StudentCreateOrderPayload {
    courseId: number

    // regular
    classId?: number

    timeslots: OneOffOrderTimeslot[] | RegularOrderTimeslot[]
}

export type AdminCreateOrderPayload = Pick<Order,
    "courseId" | "studentId" | "timeslots" | "price" | "status" | "leaveCredit" | // required
    "classId" | "paymentMethod" | "paidAt" | "refundedAt"> // optional

export type AdminCreateMultiOrderPayload = { orders: AdminCreateOrderPayload[] }

export type CheckoutPayload = Pick<Order,
    "courseId" | "timeslots" | // required
    "classId" | "referralCredit" | "leaveCredit" | "paymentMethod"> // optional

export type UpdateOrderPayload = Partial<Pick<Order, "price" | "status" | "paymentMethod" | "paidAt" | "refundedAt">>

export interface ReceiptRecord {
    imageUrl: string
    rejectedReason?: string
}

export enum OrderStatus {
    Pending,
    OfflineSubmitted,
    OfflineRejected,
    Paid,
    Cancelled,
    Refunded
}

export const orderStatusList = [
    OrderStatus.Pending,
    OrderStatus.OfflineSubmitted,
    OrderStatus.OfflineRejected,
    OrderStatus.Paid,
    OrderStatus.Cancelled,
    OrderStatus.Refunded,
];

export const unpaidStatusList = [
    OrderStatus.Pending,
    OrderStatus.OfflineSubmitted,
    OrderStatus.OfflineRejected,
];

export const orderStatusUI = {
    [OrderStatus.Pending]: {
        title: "待付款",
    },
    [OrderStatus.OfflineSubmitted]: {
        title: "已提交證明",
    },
    [OrderStatus.OfflineRejected]: {
        title: "已拒絕證明",
    },
    [OrderStatus.Paid]: {
        title: "已付款",
    },
    [OrderStatus.Cancelled]: {
        title: "已取消",
    },
    [OrderStatus.Refunded]: {
        title: "已退款",
    },
};

export enum PaymentMethod {
    CreditCard,
    Offline,
}

export const paymentMethodList = [
    PaymentMethod.CreditCard,
    PaymentMethod.Offline,
];

export const paymentMethodUI = {
    [PaymentMethod.CreditCard]: {
        title: "信用卡",
    },
    [PaymentMethod.Offline]: {
        title: "線下付款",
    },
};

export interface OneOffOrderTimeslot {
    lessonId: number
    timeslotId: number
}

export interface RegularOrderTimeslot {
    month: string
    date: string
}

export interface OrderFilter {
    keyword?: string
    courseId?: number
    courseType?: CourseType
    studentId?: string
    status?: OrderStatus
    since?: DbDate,
    until?: DbDate
    timeslotId?: number
    classId?: number
    month?: string
}

export type WebOrderRes = WebOrderCourseRes & {
    order: Order
}

export interface UnpaidRecord {
    studentId: string
    studentName: string
    courseId: number
    courseTitle: string
    month: string
}
